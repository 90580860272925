import styles from "./styles/main.module.css";
import React from "react";

function sunatToString(str) {
    switch (str) {
        case -1:
            return <div className={styles.pill} style={{backgroundColor: "#333"}}>CARGANDO...</div>;
        case '01':
            return <div className={styles.pill} style={{backgroundColor: "#333"}}>PENDIENTE</div>;
        case '03':
            return <div className={styles.pill} style={{backgroundColor: "#FFC107"}}>SUBSANADO</div>;
        case '04':
            return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>CONFORME</div>;
        case '02':
            return <div className={styles.pill} style={{backgroundColor: "#FF1235"}}>DISCONFORME</div>;
        case '05':
            return "NO VALIDO";
        case '06':
            return <div className={styles.pill} style={{backgroundColor: "#333"}}>PENDIENTE</div>;
        default:
            return "DESCONOCIDO";
    }
}
function oseSunatToString(str) {
    switch (str) {
        case -1:
            return <div className={styles.pill} style={{backgroundColor: "#333"}}>CARGANDO...</div>;
        case '0':
            return <div className={styles.pill} style={{backgroundColor: "#333"}}>NO EXISTE</div>;
        case '1':
            return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>ACEPTADO</div>;
        case '2':
            return <div className={styles.pill} style={{backgroundColor: "#FF1235"}}>ANULADO</div>;
        case '3':
            return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>AUTORIZADO</div>;
        case '4':
            return <div className={styles.pill} style={{backgroundColor: "#FF1235"}}>NO AUTORIZADO</div>;
        default:
            return "DESCONOCIDO";
    }
}

export {sunatToString, oseSunatToString};