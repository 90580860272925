import React, {useEffect, useState} from "react";
import styles from "../../../styles/main.module.css";
import Header from "../../components/header/header";
import SidebarProvider from "../../components/sidebar/sidebar";
import Title from "../../../components/title/title";
import Loading from "../../../components/loading/loading";
import TableComponent from "../../../components/table/table";
import axiosInstance from "../../../AxiosInstance";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {PrimaryButton, RadioInput, SelectInput, TextInput} from "../../../components/input/inputs";
import Modal from "../../../components/modal/modal";
import DeleteModal from "../../../components/delete_modal/delete_modal";

export default function CompanySettings() {
    const [showCreate, setShowCreate] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [form, setForm] = useState({});
    const [selectedCompany, setSelectedCompany] = useState({});

    const preDelete = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const cols = [
        {title: 'RUC', internal: 'ruc'},
        {title: 'Razón Social', internal: 'name'},
        {title: 'Categoría', internal: 'type'},
        {title: 'Estado', internal: 'active', code: (row, data) => {
            return (
                <div>{data === false ? 'Inactivo' : 'Activo'}</div>
            );
            }},
        {
            title: 'Acciones',
            buttons: [
                {
                    icon: faEdit,
                    color: "#44A04A",
                    onClick: (id) => {
                        setShowEdit(true)
                        let found = rows.filter((x) => x.id === id)[0];
                        setSelectedCompany(found);
                        found.active = found.active ? 1 : 0;
                        console.log(found)
                        setForm(found);
                    }
                },
                {
                    icon: faTrash,
                    color: "#DC3545",
                    onClick: preDelete
                }
            ]
        }
    ]

    const [rows, setRows] = useState([])

    useEffect(() => {
        axiosInstance.get('company/').then((response) => {
            setRows(response.data.results);
        })
    }, [])

    const create = () => {
        if(Object.keys(selectedCompany).length > 0) {
            axiosInstance.put(`company/${selectedCompany.id}/`, form).then((response) => {
                window.location.reload();
            }).catch((err) => {

            });
        } else {
            axiosInstance.post('company/', form).then((response) => {
                window.location.reload();
            }).catch((err) => {

            });
        }
    }

    const deleteObj = () => {
        axiosInstance.delete('company/' + deleteId + '/').then((response) => {
            window.location.reload();
        });
    }

    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <div className={styles.mainContainer}>
            <Header setSidebarOpen={setSidebarOpen}/>
            <SidebarProvider isOpen={sidebarOpen} index={3}/>

            <Modal show={showCreate} setter={setShowCreate} name={'Agregar Empresa'} buttonName={'Guardar'} onClick={create}>
                <div className={'d-flex justify-content-between'}>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Razón Social'} internalName={'name'} form={form} setForm={setForm}/>
                    </div>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'RUC'} internalName={'ruc'} form={form} setForm={setForm}/>
                    </div>
                </div>

                <TextInput grey={true} name={'Email'} internalName={'email'} form={form} setForm={setForm}/>
                <TextInput grey={true} name={'Descripción'} internalName={'description'} form={form} setForm={setForm}/>
                <div className={'d-flex justify-content-between'}>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Usuario SOL'} internalName={'sunat_sol_username'} form={form} setForm={setForm}/>
                    </div>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Clave SOL'} internalName={'sunat_sol_password'} form={form} setForm={setForm}/>
                    </div>
                </div>

                <div><b>Conformidad</b></div>
                <div className={'d-flex justify-content-between'}>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Sunat ID'} internalName={'sunat_client_id'} form={form} setForm={setForm}/>
                    </div>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Sunat Secret'} internalName={'sunat_client_secret'} form={form} setForm={setForm}/>
                    </div>
                </div>

                <div><b>Estado</b></div>
                <div className={'d-flex justify-content-between'}>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Sunat ID'} internalName={'sunat_client_id_status'} form={form} setForm={setForm}/>
                    </div>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Sunat Secret'} internalName={'sunat_client_secret_status'} form={form} setForm={setForm}/>
                    </div>
                </div>

                <RadioInput form={form} setForm={setForm} name={'Estado'} internalName={'active'} options={['Inactivo', 'Activo']}/>
            </Modal>

            <Modal show={showEdit} setter={setShowEdit} name={'Editar Empresa'} buttonName={'Guardar'} onClick={create}>
                <div className={'d-flex justify-content-between'}>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Razón Social'} internalName={'name'} form={form} setForm={setForm}/>
                    </div>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'RUC'} internalName={'ruc'} form={form} setForm={setForm}/>
                    </div>
                </div>

                <TextInput grey={true} name={'Email'} internalName={'email'} form={form} setForm={setForm}/>
                <TextInput grey={true} name={'Descripción'} internalName={'description'} form={form} setForm={setForm}/>

                <div className={'d-flex justify-content-between'}>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Usuario SOL'} internalName={'sunat_sol_username'} form={form} setForm={setForm}/>
                    </div>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Clave SOL'} internalName={'sunat_sol_password'} form={form} setForm={setForm}/>
                    </div>
                </div>

                <div><b>Conformidad</b></div>

                <div className={'d-flex justify-content-between'}>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Sunat ID'} internalName={'sunat_client_id'} form={form} setForm={setForm}/>
                    </div>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Sunat Secret'} internalName={'sunat_client_secret'} form={form} setForm={setForm}/>
                    </div>
                </div>

                <div><b>Estado</b></div>

                <div className={'d-flex justify-content-between'}>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Sunat ID'} internalName={'sunat_client_id_status'} form={form} setForm={setForm}/>
                    </div>
                    <div style={{
                        flexBasis: '48%'
                    }}>
                        <TextInput grey={true} name={'Sunat Secret'} internalName={'sunat_client_secret_status'} form={form} setForm={setForm}/>
                    </div>
                </div>

                <RadioInput form={form} setForm={setForm} name={'Estado'} internalName={'active'} options={['Inactivo', 'Activo']}/>
            </Modal>

            <DeleteModal show={showDelete} setter={setShowDelete} name={'la empresa'} buttonName={'Guardar'} onClick={deleteObj}/>

            <div className={styles.container} style={!sidebarOpen ? {marginLeft: '80px'} : {marginLeft: '285px'}}>
                <Title name={'Creación de Empresas'}/>

                <div className={`${styles.secondaryContainer}`}>
                    <div className={'d-flex justify-content-end'}>
                        <PrimaryButton name={'Agregar Empresa'} onClick={() => {
                            setShowCreate(true);
                            setForm({});
                        }}/>
                    </div>
                    {
                        //Pagination HERE
                        rows.length === 0 ? <Loading/> :
                            <TableComponent
                                fetchUrl={'company'}
                                pull={setRows}
                                cols={cols}
                                rows={rows}
                            />
                    }
                </div>
            </div>
        </div>
    );
}