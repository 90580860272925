import {createContext, useEffect, useState} from "react";
import axiosInstance from "./AxiosInstance";

const MyContext = createContext("");

export function ContextProvider({children}) {
    const [user, setUser] = useState({});
    const [isClient, setIsClient] = useState(false);

    let authenticated = localStorage.getItem('access_token') !== null;

    useEffect(() => {
        if(authenticated) {
            axiosInstance.get('current_user/').then((response) => {
                setUser(response.data)
                if(response.data.is_staff) {
                    setIsClient(true);
                }
            })
        }
    }, [authenticated])

    return (
        <MyContext.Provider value={{user, isClient}}>
            {children}
        </MyContext.Provider>
    );
}

export default MyContext;