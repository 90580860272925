import React, {useContext, useEffect, useState} from "react";
import styles from "../../../styles/main.module.css";
import Header from "../../components/header/header";
import SidebarProvider from "../../components/sidebar/sidebar";
import Title from "../../../components/title/title";
import {PrimaryButton, TextInput} from "../../../components/input/inputs";
import MyContext from "../../../MyContext";
import axiosInstance from "../../../AxiosInstance";

export default function ProviderProfile() {
    const [form, setForm] = useState({});

    useEffect(() => {
        axiosInstance.get('current_user/').then((response) => {
            response.data.password = "";
            setForm(response.data);
        })
    }, []);

    const saveChanges = () => {
        if((form.password !== "" && form.password_2 !== "") || (form.password !== undefined && form.password_2 !== undefined)) {
            if (form.password !== form.password_2) {
                alert('Las contraseñas no coinciden');
                return;
            }
        }

        axiosInstance.put('modify_user/1/', form).then((response) => {
            window.location.reload();
        })
    }

    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <div className={styles.mainContainer}>
            <Header setSidebarOpen={setSidebarOpen}/>
            <SidebarProvider isOpen={sidebarOpen} index={2}/>

            <div className={styles.container} style={!sidebarOpen ? {marginLeft: '80px'} : {marginLeft: '285px'}}>
                <Title name={'Actualización de Perfil'}/>

                <div className={`${styles.secondaryContainer}`}>
                    <div className={`${styles.modalSubtitle} mb-3 mt-2`}>Datos Personales</div>

                    <div className={'d-flex'}>
                        <div style={{flexBasis: "49%", marginRight: "2%"}}>
                            <TextInput grey={true} form={form} setForm={setForm} name={'Nombres'} internalName={'first_name'}/>
                        </div>
                        <div style={{flexBasis: "49%"}}>
                            <TextInput grey={true} form={form} setForm={setForm} name={'Apellidos'} internalName={'last_name'}/>
                        </div>
                    </div>

                    <div className={'d-flex'}>
                        <div style={{flexBasis: "49%", marginRight: "2%"}}>
                            <TextInput grey={true} form={form} setForm={setForm} name={'Correo electrónico'} internalName={'username'}/>
                        </div>
                        <div style={{flexBasis: "49%"}}>
                            <TextInput grey={true} form={form} setForm={setForm} name={'DNI'} internalName={'dni'} type={'number'}/>
                        </div>
                    </div>

                    <TextInput grey={true} form={form} setForm={setForm} name={'Dirección'} internalName={'address'}/>
                    <TextInput grey={true} form={form} setForm={setForm} name={'Teléfono'} internalName={'phone'} type={'number'}/>

                    <div className={'d-flex'}>
                        <div style={{flexBasis: "49%", marginRight: "2%"}}>
                            <TextInput grey={true} form={form} setForm={setForm} name={'Contraseña'} internalName={'password'}/>
                        </div>
                        <div style={{flexBasis: "49%"}}>
                            <TextInput grey={true} form={form} setForm={setForm} name={'Repita Contraseña'} internalName={'password_2'}/>
                        </div>
                    </div>

                    <div className={'d-flex justify-content-center'}>
                        <PrimaryButton name={'Guardar'} onClick={saveChanges}/>
                    </div>
                </div>
            </div>
        </div>
    );
}