import React from "react";
import styles from './modal.module.css';
import {Modal as BootStrapModal} from "react-bootstrap";
import {DangerButton, PrimaryButton} from "../input/inputs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";

export default function Modal({show, setter, name, description, children, buttonName, onClick, size, buttons, otherSetter, hideClose}) {
    return (
        <BootStrapModal show={show} className={styles.container} centered={true} size={size}>
            <div className={styles.header}>
                <div className={'d-flex justify-content-between align-items-center'}>
                    <div>{name}</div>
                    <FontAwesomeIcon icon={faX} onClick={() => setter(false)} role={'button'}/>
                </div>
                <div>{description}</div>
            </div>

            <div style={{
                paddingLeft: '1rem',
                paddingRight: '1rem',
            }}>
                <div className={styles.body}>
                    {children}
                </div>

                <div className={styles.footer}>
                    <div>
                        {buttonName && <PrimaryButton name={buttonName} onClick={onClick}/>}
                    </div>
                    <div>
                        {buttons}
                    </div>
                    {!hideClose && <DangerButton onClick={otherSetter ? () => otherSetter() : () => setter(false)} name={'Cerrar'}/>}
                </div>
            </div>
        </BootStrapModal>
    );
}