// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_mainContainer__4b8gt {
    background-color: #F4F5FA;
    width: 285px;
    height: 100% !important;
    padding-right: 1%;
    font-family: "Century Gothic";
    position: fixed;
    margin-top: 80px;
}

.sidebar_smallMainContainer__gagr7 {
    background-color: #F4F5FA;
    width: 80px;
    height: 100% !important;
    padding-right: 1%;
    font-family: "Century Gothic";
    position: fixed;
    margin-top: 80px;
}

.sidebar_categoryTitle__nwDnt {
    text-transform: uppercase;
    font-weight: 700;
    color: #3A3541;
    opacity: 40%;
}

.sidebar_line__G6uFk {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #3A3541;
    opacity: 40%;
    font-size: small;
}

.sidebar_line__G6uFk::before,
.sidebar_line__G6uFk::after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background-color: black;
    margin: 0 10px;
}

.sidebar_item__02y7T {
    -webkit-user-select: none;
            user-select: none;
    display: flex;
    min-height: 47px;
    flex-direction: row;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 10px;
    color: #3A3541;
    opacity: 80%;
    font-size: large;
    align-items: center;
}

.sidebar_selected__0gkE2 {
    color: #FFF;
}
`, "",{"version":3,"sources":["webpack://./src/providers/components/sidebar/sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,uBAAuB;IACvB,iBAAiB;IACjB,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,6BAA6B;IAC7B,gCAAgC;IAChC,aAAa;IACb,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".mainContainer {\n    background-color: #F4F5FA;\n    width: 285px;\n    height: 100% !important;\n    padding-right: 1%;\n    font-family: \"Century Gothic\";\n    position: fixed;\n    margin-top: 80px;\n}\n\n.smallMainContainer {\n    background-color: #F4F5FA;\n    width: 80px;\n    height: 100% !important;\n    padding-right: 1%;\n    font-family: \"Century Gothic\";\n    position: fixed;\n    margin-top: 80px;\n}\n\n.categoryTitle {\n    text-transform: uppercase;\n    font-weight: 700;\n    color: #3A3541;\n    opacity: 40%;\n}\n\n.line {\n    display: flex;\n    align-items: center;\n    text-transform: uppercase;\n    font-weight: 700;\n    color: #3A3541;\n    opacity: 40%;\n    font-size: small;\n}\n\n.line::before,\n.line::after {\n    content: \"\";\n    flex-grow: 1;\n    height: 1px;\n    background-color: black;\n    margin: 0 10px;\n}\n\n.item {\n    user-select: none;\n    display: flex;\n    min-height: 47px;\n    flex-direction: row;\n    border-top-right-radius: 30px;\n    border-bottom-right-radius: 30px;\n    padding: 10px;\n    color: #3A3541;\n    opacity: 80%;\n    font-size: large;\n    align-items: center;\n}\n\n.selected {\n    color: #FFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `sidebar_mainContainer__4b8gt`,
	"smallMainContainer": `sidebar_smallMainContainer__gagr7`,
	"categoryTitle": `sidebar_categoryTitle__nwDnt`,
	"line": `sidebar_line__G6uFk`,
	"item": `sidebar_item__02y7T`,
	"selected": `sidebar_selected__0gkE2`
};
export default ___CSS_LOADER_EXPORT___;
