import React, {useEffect, useRef, useState} from "react";
import mystyles from './styles/table.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axiosInstance from "../../AxiosInstance";

export default function TableComponent({fetchUrl, cols, rows, pull, withIndex, noPagination, defaultFilters}) {
    const [activeFilter, setActiveFilter] = useState(null);
    const filterInputRef = useRef(null);
    const [filters, setFilters] = useState({...defaultFilters});

    const setField = (name, value) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const getValueFromString = (object, accessString) => {
        let accessParts = accessString.split(".");
        let value = object;

        for (let i = 0; i < accessParts.length; i++) {
            if (!value) {
                return "";
            }
            let accessPart = accessParts[i];
            value = value[accessPart];
        }

        return value;
    }

    const renderTh = (col, index) => {
        if(index === activeFilter && col.filter === true) {
            return (
                <th key={index}>
                    <div ref={filterInputRef}>
                        <input value={col.filterInternal ? filters[col.filterInternal] : filters[col.internal]} onChange={(event) => setField(col.filterInternal ? col.filterInternal : col.internal, event.target.value)} type={'text'} placeholder={`Filter ${col.title}`}/>
                    </div>
                </th>
            )
        } else {
            return <th key={index} onClick={() => setActiveFilter(index)}>{col.title} { (col.filterInternal ? filters[col.filterInternal] : filters[col.internal]) && "*"}</th>
        }
    }

    useEffect(() => {
        if(filters) {
            const queryParams = Object.entries(filters)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            const url = `${fetchUrl}/?${queryParams}`;
            axiosInstance.get(url).then((response) => {
                if(noPagination) {
                    pull(response.data)
                } else {
                    pull(response.data.results)
                }
            });
        }
    }, [filters])

    return (
        <div style={{ overflowX: 'auto' }}>
        <table className={`${mystyles.table}`}>
            <thead>
            {withIndex && <th>N°</th>}
            {cols.map((col, index) => (
                renderTh(col, index)
            ))}
            </thead>
            <tbody>
            {rows.map((row, index) => (
                <tr key={index}>
                    {withIndex && <td>{index + 1}</td>}
                    {cols.map((col, colIndex) => (
                        <td>
                            {col.buttons ?
                                <span className={mystyles.actionButtons}>
                                            {col.buttons.map((button, buttonIndex) => (
                                                <div role={'button'} onClick={() => {button.onClick(row.id)}}>
                                                    <FontAwesomeIcon icon={button.icon} key={buttonIndex} color={button.color}/>
                                                </div>
                                            ))
                                            }
                                        </span>
                                :
                                <>
                                    {col.image ?
                                        <img src={getValueFromString(row,col.image.url)} alt={col.title} width={"50px"} height={"50px"} style={{borderRadius: "5px", objectFit: "contain", backgroundColor: "#F7F8F9"}}/>
                                        :
                                        <>
                                        {
                                            col.isBoolean ? <span>{getValueFromString(row, col.internal) ? "Sí" : "No"}</span> :
                                                <>
                                                { col.code ? <>{col.code(row, getValueFromString(row, col.internal))}</>
                                                :
                                                    <span>{getValueFromString(row, col.internal)}</span>
                                                }
                                                </>
                                        }
                                        </>
                                    }
                                </>
                            }
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
        </div>
    )
}