// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_loadingScreen__75QIJ {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 10rem;
}

.loading_loadingSpinner__KfZM9 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #7D81D4;
    border-top: 4px solid #4A4DB9;
    animation: loading_spin__X9Z3Q 1s linear infinite;
}

.loading_loadingScreen__75QIJ div:last-child {
    font-weight: 500;
    font-style: italic;
    color: #6B7280;
    font-size: large;
}

@keyframes loading_spin__X9Z3Q {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/loading/loading.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,6BAA6B;IAC7B,iDAAkC;AACtC;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loadingScreen {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n    padding-top: 10rem;\n}\n\n.loadingSpinner {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    border: 4px solid #7D81D4;\n    border-top: 4px solid #4A4DB9;\n    animation: spin 1s linear infinite;\n}\n\n.loadingScreen div:last-child {\n    font-weight: 500;\n    font-style: italic;\n    color: #6B7280;\n    font-size: large;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingScreen": `loading_loadingScreen__75QIJ`,
	"loadingSpinner": `loading_loadingSpinner__KfZM9`,
	"spin": `loading_spin__X9Z3Q`
};
export default ___CSS_LOADER_EXPORT___;
