import React, {useEffect, useState} from "react";
import styles from './login.module.css';
import {PrimaryButton, TextInput} from "../../components/input/inputs";
import {Link} from "react-router-dom";
import axiosInstance from "../../AxiosInstance";

export default function LoginProvider() {
    const [showError, setShowError] = useState(false);
    const [form, setForm] = useState({});
    const [systemSettings, setSystemSettings] = useState({});

    useEffect(() => {
        let systemSettingsJSON = localStorage.getItem('system_settings');

        if (systemSettingsJSON) {
            // Parse the JSON data and update the state if it exists
            setSystemSettings(JSON.parse(systemSettingsJSON));
        } else {
            axiosInstance.get('system_settings/1/').then((response) => {
                localStorage.setItem('system_settings', JSON.stringify(response.data));
                setSystemSettings(response.data);
            });
        }
    }, []);

    const onClick = () => {
        axiosInstance.post('token/', form).then((response) => {
            setShowError(false);
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
            window.location.replace('/provider/buy_order')
        }).catch((err) => {
            setShowError(true);
        })
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.provider} style={{backgroundImage: "url('images/login_provider.jpg')"}}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    position: 'absolute',
                }}>

                </div>
                <div className={styles.logoBackground}>
                    <img src={'/images/logo_colored.png'}/>
                </div>
            </div>
            <div className={styles.login}>
                <div className={`${styles.title} mb-4`}>Portal de Proveedores</div>
                <div className={`d-flex flex-row justify-content-center mb-5`}>
                    <div>¿Aún no tienes cuenta?</div>
                    <Link to={'/register'} style={{color: "#4A4DB9"}} role={'button'}>Regístrate</Link>
                </div>

                <div className={styles.loginBox}>
                    <div className={styles.loginTitle}>Iniciar sesión</div>
                    <div className={`mt-1 mb-3`}>Por favor, introduce tu RUC, usuario y contraseña</div>

                    <div>
                        <TextInput name={'RUC'} internalName={'ruc'} form={form} hideName={true} setForm={setForm} placeholder={'Ingresa el número de RUC de tu empresa'}/>
                        <TextInput name={'CORREO'} internalName={'username'} hideName={true} form={form} setForm={setForm} placeholder={'Ingresa tu correo electrónico'}/>
                        <TextInput name={'CONTRASEÑA'} type={'password'} hideName={true} internalName={'password'} form={form} setForm={setForm} placeholder={'Ingresa tu contraseña'}/>

                        <div className={'d-flex flex-row align-items-end'}>
                            {/*<div role={'button'} className={'mb-3'} style={{color: "#4A4DB9", fontWeight: "700"}}>¿Olvidaste tu contraseña?</div>*/}
                        </div>
                        <PrimaryButton style={{
                            backgroundColor: 'rgba(231, 82, 99, 255)'
                        }} name={'Iniciar sesión'} onClick={onClick}/>
                    </div>
                </div>
            </div>
        </div>
    );
}