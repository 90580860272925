import React from "react";
import styles from '../modal/modal.module.css';
import {Modal as BootStrapModal} from "react-bootstrap";
import {DangerButton, PrimaryButton} from "../input/inputs";

export default function DeleteModal({show, setter, name, onClick}) {
    return (
        <BootStrapModal show={show} className={styles.container} centered={true}>
            <div className={`${styles.header} ${styles.noBorder}`}>
                <div className={'d-flex justify-content-center align-items-center'} style={{textAlign:"center"}}>
                    <div>¿Seguro que desea eliminar {name}?</div>
                </div>
            </div>

            <div className={styles.footer}>
                <div className={'ms-3'}>
                    <PrimaryButton name={'Si'} onClick={onClick}/>
                </div>
                <DangerButton onClick={() => setter(false)} name={'No'}/>
            </div>
        </BootStrapModal>
    );
}