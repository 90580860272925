import React from "react";
import mystyles from './loading.module.css';

export default function Loading() {
    return (
        <div className={mystyles.loadingScreen}>
            <div className={mystyles.loadingContainer}>
                <div className={mystyles.loadingSpinner}/>
            </div>
            <div>Loading...</div>
        </div>
    );
}