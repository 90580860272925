// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_headerContainer__LApN\\+ {
    background-color: #000;
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}

.header_headerContainer__LApN\\+ div:first-child {
    text-align: left;
}

.header_headerContainer__LApN\\+ div:first-child div img {
    width: 23%;
}

.header_headerContainer__LApN\\+ div:last-child img {
    margin-left: 10px;
    width: 100%;
}

.header_dropdown__xHMau {
    position: absolute;
    top: 70px;
    right: 50px;
    z-index: 1;
    background-color: #fff;
    border: 3px solid white;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    min-width: 160px;
    transition: opacity 1s ease-out;
    opacity: 1;
}

.header_hide__uJdke {
    opacity: 0;
    display: none;
}

.header_dropdownItem__uUjB0 {
    font-weight: 500;
    text-align: left;
    display: block;
    padding: 8px 15px;
    color: #162949;
    cursor: pointer;
    text-decoration: none;
}

.header_dropdownItem__uUjB0:hover {
    background-color: #F6F7FC;
}

.header_sidebarShow__97hTc {
    margin-left: 1rem;
    border-radius: 50%;
    padding: 2px;
    border: #fff solid 2px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/providers/components/header/header.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,UAAU;IACV,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,yCAAyC;IACzC,gBAAgB;IAChB,+BAA+B;IAC/B,UAAU;AACd;;AAEA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":[".headerContainer {\n    background-color: #000;\n    width: 100%;\n    position: fixed;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    z-index: 999;\n}\n\n.headerContainer div:first-child {\n    text-align: left;\n}\n\n.headerContainer div:first-child div img {\n    width: 23%;\n}\n\n.headerContainer div:last-child img {\n    margin-left: 10px;\n    width: 100%;\n}\n\n.dropdown {\n    position: absolute;\n    top: 70px;\n    right: 50px;\n    z-index: 1;\n    background-color: #fff;\n    border: 3px solid white;\n    border-radius: 5px;\n    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);\n    min-width: 160px;\n    transition: opacity 1s ease-out;\n    opacity: 1;\n}\n\n.hide {\n    opacity: 0;\n    display: none;\n}\n\n.dropdownItem {\n    font-weight: 500;\n    text-align: left;\n    display: block;\n    padding: 8px 15px;\n    color: #162949;\n    cursor: pointer;\n    text-decoration: none;\n}\n\n.dropdownItem:hover {\n    background-color: #F6F7FC;\n}\n\n.sidebarShow {\n    margin-left: 1rem;\n    border-radius: 50%;\n    padding: 2px;\n    border: #fff solid 2px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `header_headerContainer__LApN+`,
	"dropdown": `header_dropdown__xHMau`,
	"hide": `header_hide__uJdke`,
	"dropdownItem": `header_dropdownItem__uUjB0`,
	"sidebarShow": `header_sidebarShow__97hTc`
};
export default ___CSS_LOADER_EXPORT___;
