import styles from './date.module.css';
import { DateRange } from 'react-date-range';
import { es } from 'react-date-range/dist/locale';
import React, {useEffect, useRef, useState} from "react";
import * as rdrLocales from 'react-date-range/dist/locale';
export default function DateInput({className, style, onChange, value, placeholder}) {
    const dateRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (dateRef.current && !dateRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    return (
        <div className={styles.container} onClick={() => setIsOpen(true)} ref={dateRef}>
            <div>
                <div className={styles.floatingLabel}>
                    {placeholder}
                </div>
                <div className={`${styles.defaultBorder} form-control rounded-2`}>
                    {state[0]?.startDate?.toLocaleDateString('es')} - {state[0]?.endDate?.toLocaleDateString('es')}
                </div>
            </div>
            {isOpen &&
                <div className={`${styles.dateContainer}`}>
                    <DateRange
                        dateDisplayFormat='dd/MM/yyyy'
                        editableDateInputs={true}
                        onChange={(item) =>
                        {
                            setState([item.selection]);
                            if(onChange) onChange(item.selection);
                        }
                        }
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                    />
                </div>
            }
        </div>
    );
}