import React, {useContext, useEffect, useState} from "react";
import Header from "../components/header/header";
import SidebarProvider from "../components/sidebar/sidebar";
import styles from '../../styles/main.module.css';
import {
    BigTextInput,
    DangerButton,
    FileInput,
    PrimaryButton,
    SelectInput,
    TextInput
} from "../../components/input/inputs";
import TableComponent from "../../components/table/table";
import Loading from "../../components/loading/loading";
import Title from "../../components/title/title";
import axiosInstance from "../../AxiosInstance";
import Modal from "../../components/modal/modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faClipboard,
    faDollar,
    faDownload,
    faMagnifyingGlass,
    faSave,
    faWarning,
    faX
} from "@fortawesome/free-solid-svg-icons";
import MyContext from "../../MyContext";
import {toast, ToastTypes} from "../../components/toasts/toast/toast";
import {DateInput as DateInputAjonjolib, Select} from "../../components/ajonjolib/inputs/ajonjolinput";


function DetailEntry({name, data, code, unit}) {
    return (
        <div className={'mb-3'}>
            <div  className={`${styles.modalSmallSubtitle}`}>{name}</div>
            <div className={'mt-1'}>{data ? data : (code ? '' : (name ? '-' : ''))} {unit ? unit : ''}</div>
            {code && code}
        </div>
    );
}

export default function InvoicesProvider() {
    const [form, setForm] = useState({});
    const [observeForm, setObserveForm] = useState({});
    const [docForm, setDocForm] = useState({});
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [showRevisionModal, setShowRevisionModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showObserve, setShowObserve] = useState(false);
    const [invoice, setInvoice] = useState({client: {}, currency: {}, purchase_order: {provider: {}, client: {}}, operation_type: {},detraction_currency: {}, order_detail: {}, observations: []});
    const {isClient} = useContext(MyContext);
    const [secondaryQuery, setSecondaryQuery] = useState("");


    const [showModalAccept, setShowModalAccept] = useState(false);
    const [showModalReject, setShowModalReject] = useState(false);
    const [showModalPayment, setShowModalPayment] = useState(false);
    const [showObservationText, setShowObservationText] = useState(false);
    const [observationText, setObservationText] = useState("");



    useEffect(() => {
        axiosInstance.get('bill/').then((response) => {
            setRows(response.data.results);
            setFilteredRows(response.data.results);
        });
    }, []);

    const formatTimestamp = (timestamp) => {
        if(timestamp == null) return "-";
        const date = new Date(timestamp);
        return date.toLocaleString();
    }

    const query = () => {
        const queryParams = Object.entries(form)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");
        const url = `bill/?${queryParams}`;
        axiosInstance.get(url).then((response) => {
            setRows(response.data.results);
            setFilteredRows(response.data.results);
        })
    }

    const evalToString = (str) => {
        switch (str) {
            case -1:
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>CARGANDO...</div>;
            case 0:
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>PENDIENTE</div>;
            case 1:
                return <div className={styles.pill} style={{backgroundColor: "#FFC107"}}>OBSERVADO</div>;
            case 2:
                return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>ACEPTADO</div>;
            case 3:
                return <div className={styles.pill} style={{backgroundColor: "#FF1235"}}>RECHAZADO</div>;
            default:
                return "DESCONOCIDO";
        }
    }

    const sunatToString = (str) => {
        switch (str) {
            case -1:
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>CARGANDO...</div>;
            case '01':
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>PENDIENTE</div>;
            case '03':
                return <div className={styles.pill} style={{backgroundColor: "#FFC107"}}>SUBSANADO</div>;
            case '04':
                return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>CONFORME</div>;
            case '02':
                return <div className={styles.pill} style={{backgroundColor: "#FF1235"}}>DISCONFORME</div>;
            case '05':
                return "NO VALIDO";
            case '06':
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>PENDIENTE</div>;
            default:
                return "DESCONOCIDO";
        }
    }
    const oseSunatToString = (str) => {
        switch (str) {
            case -1:
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>CARGANDO...</div>;
            case '0':
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>NO EXISTE</div>;
            case '1':
                return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>ACEPTADO</div>;
            case '2':
                return <div className={styles.pill} style={{backgroundColor: "#FF1235"}}>ANULADO</div>;
            case '3':
                return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>AUTORIZADO</div>;
            case '4':
                return <div className={styles.pill} style={{backgroundColor: "#FF1235"}}>NO AUTORIZADO</div>;
            default:
                return "DESCONOCIDO";
        }
    }


    const pagoToString = (str) => {
        if(str === 0) return <div className={styles.pill} style={{backgroundColor: "#333"}}>PENDIENTE</div>;
        return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>RECIBIDO</div>;
    }

    const cols = [
        {title: "Fecha recepción" , internal: "reception_date"},
        {title: "Fecha emisión" , internal: "issue_date"},
        {title: "Tipo Doc.", internal: "type", code: (row, data) => {
            const val = data === 0 ? 'BOLETA' : 'FACTURA';
            return val;
            }},
        {title: "Serie - Número", internal: "sn"},
        {title: "Cliente", internal: "purchase_order.client.name"},
        {title: "Moneda", internal: "currency.name"},
        {title: "Monto Total", internal: "total"},
        {title: "Estado Evaluación", internal: "eval_status", code: (row, data) => {
            return <div>{evalToString(data)}</div>;
            }},
        {title: "Estado Pago", internal: "payment_status", code: (row, data) => {
            return <div>{pagoToString(data)}</div>;
            }},
        {title: "Acciones", internal: 'type', code: (row, data) => {
            return (
                <div
                    onClick={() => {
                        row.sunat_status = -1;
                        row.conformity_status = -1;
                        setInvoice(row)
                        setShowDetails(true);
                        axiosInstance.get(`sunat/${row.id}/`).then((response) => {
                            console.log(response);
                            if(response) {
                                console.log(response.data);
                                if (response?.status === 200 && response?.data)
                                {
                                    const response_data = response.data;
                                    setInvoice({
                                        ...row,
                                        sunat_status: response_data.ose_sunat_status,
                                        conformity_status: response_data.conformity_status
                                    });
                                }
                            } else {
                                setInvoice({
                                    ...row,
                                    sunat_status: -2,
                                    conformity_status: -2
                                });
                            }
                        }).catch((err) => {
                            console.log(err);
                            setInvoice({
                                ...row,
                                sunat_status: -2,
                                conformity_status: -2
                            });
                        })
                    }}
                    role={'button'} className={styles.pill} style={{backgroundColor: "#FFF", border: "#333333 solid 1px", color: "#333333"}}>Detalles</div>
            );
            }}
    ];

    const setStatus = (status, conformity) => {
        axiosInstance.post('sunat/', {
            ruc_provider: invoice.purchase_order.provider.ruc,
            ruc_client: invoice.purchase_order.client.ruc,
            bill_id: invoice.id,
            conformity: conformity
        }).then((response) => {
            if(response.status === 200) {
                axiosInstance.put('bill_status/' + invoice.id + '/', {
                    status: status
                }).then((response) => {
                    window.location.reload();
                });
            }
        })
    }

    const setPayment = () => {
        axiosInstance.post('bill_status/' + invoice.id + '/').then((response) => {
            window.location.reload();
        })
    }

    const observe = () => {
        let formData = new FormData();
        for (const key in observeForm) {
            if (key === 'observation') {
                // If the key is 'observation', it's an array of files
                const files = observeForm[key];
                for (let i = 0; i < files.length; i++) {
                    formData.append(key, files[i]);
                }
            } else {
                formData.append(key, observeForm[key]);
            }
        }
        formData.append("bill_id", invoice.id);

        axiosInstance.post('observation/', formData).then((response) => {
            window.location.reload()
        });
    }

    const correct = () => {
        let formData = new FormData();
        for (const key in docForm) {
            if (key === 'correction') {
                // If the key is 'observation', it's an array of files
                const files = docForm[key];
                for (let i = 0; i < files.length; i++) {
                    formData.append(key, files[i]);
                }
            } else {
                formData.append(key, docForm[key]);
            }
        }
        formData.append("bill_id", invoice.id);

        axiosInstance.post('correction/', formData).then((response) => {
            window.location.reload()
        });
    }

    const downloadFile = (id, name) => {
        axiosInstance.get(`file/${id}/`, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}`);
            document.body.appendChild(link);

            // Simulate a click event to trigger the download
            link.click();

            // Clean up the temporary link and URL object
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    const rechazar = () => {
        const reason = form['rejection_reason'][0];
        const comment = form['rejection_comment'];
        console.log(reason);
        console.log(comment)

        toast('Factura Rechazada', ToastTypes.ERROR);

        axiosInstance.post('sunat/', {
            ruc_client: invoice.purchase_order.client.ruc,
            ruc_provider: invoice.purchase_order.provider.ruc,
            bill_id: invoice.id,
            conformity: 2,
            motives: reason,
            comment: comment
        }).then((response) => {
            if(response.status === 200) {
                axiosInstance.put('bill_status/' + invoice.id + '/', {
                    status: 3
                }).then((response) => {
                    window.location.reload();
                })
            }
        });


    }

    useEffect(() => {
        if(secondaryQuery === undefined || secondaryQuery === "") setFilteredRows(rows);
        const lowerCaseQuery = secondaryQuery.toLowerCase();
        const filtered = rows.filter((row) => {
            return (row?.sn?.toString()?.toLowerCase().includes(lowerCaseQuery)
                || row?.purchase_order?.client?.name?.toLowerCase().includes(lowerCaseQuery)
                || row?.total?.toString().toLowerCase().includes(lowerCaseQuery)
                || row?.total?.toString().toLowerCase().includes(lowerCaseQuery));
        })
        setFilteredRows(filtered);
    }, [secondaryQuery]);

    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <div className={styles.mainContainer}>
            <Header setSidebarOpen={setSidebarOpen}/>
            <SidebarProvider index={1} isOpen={sidebarOpen}/>

            <Modal show={showModalAccept} setter={setShowModalAccept} name={'Aceptar'} onClick={() => setStatus(2, 1)} buttonName={'Aceptar'}>
                <div className={'mb-3'}>¿Seguro que deseas aceptar la factura {invoice?.sn}?</div>
            </Modal>

            <Modal show={showModalReject} setter={setShowModalReject} name={'Rechazar'} onClick={() => rechazar()} buttonName={'Rechazar'}>
                <div className={'mb-3'}>¿Seguro que deseas rechazar la factura {invoice?.sn}?</div>
                <div>Motivo de rechazo: </div>
                <Select options={[{value: 1, name: 'Plazo de pago acordado'}, {value: 2, name: 'Monto neto pendiente de pago'}, {value: 3, name: 'Reclamo respecto de bienes adquiridos o servicios prestados'}]} value={form['rejection_reason']} onChange={(value) => setForm({...form, rejection_reason: value})}/>
                <div className={'mb-2'}/>
                <div>Comentario: </div>
                <TextInput value={form['rejection_comment']} internalName={'rejection_comment'} form={form} setForm={setForm}/>
                <div className={'mb-3'}/>
            </Modal>

            <Modal show={showModalPayment} setter={setShowModalPayment} name={'Confirmar Pago'} onClick={() => setPayment()} buttonName={'Confirmar'}>
                <div className={'mb-3'}>¿Seguro que deseas confirmar el pago de la factura {invoice?.sn}?</div>
            </Modal>

            <Modal hideClose={true} size={'xl'} show={showDetails} setter={setShowDetails} name={'Detalle del Comprobante'}>
                <div className={`${styles.modalSubtitle} mb-2`}>Datos del comprobante</div>
                <div className={'d-flex justify-content-between px-2'}>
                    <div className={'d-flex flex-column'}>
                        <DetailEntry name={'Tipo Comprobante:'} data={invoice.type === 0 ? 'Boleta' : 'Factura'}/>
                        <DetailEntry name={'Fecha de Emisión:'} data={invoice.issue_date}/>
                        <DetailEntry name={'Forma de Pago:'} data={invoice?.payment_terms ? invoice.payment_terms : '-'}/>
                        <DetailEntry name={'Monto Detracción:'} code={
                            <div>{invoice?.detraction_amount ? (parseFloat(invoice?.detraction_amount).toFixed(2)) : '-'}</div>
                        }/>
                        <DetailEntry name={'Orden de Compra:'} data={invoice.purchase_order.number}/>
                    </div>

                    <div className={'d-flex flex-column'}>
                        <DetailEntry name={'Serie y Número:'} data={invoice?.sn}/>
                        <DetailEntry name={'Fecha de Vencimiento:'} data={invoice.due_date}/>
                        <DetailEntry name={'Moneda:'} data={invoice.currency.name}/>
                        <DetailEntry name={'Moneda Detracción:'} data={invoice.detraction_currency?.name}/>
                        <DetailEntry name={'N° NEC:'} data={invoice.order_detail.nec_number}/>
                    </div>

                    <div className={'d-flex flex-column'}>
                        <DetailEntry name={'RUC del Emisor:'} data={invoice.purchase_order.provider.ruc}/>
                        <DetailEntry name={'RUC del Cliente:'} data={invoice.purchase_order.client.ruc}/>
                        <DetailEntry name={'Monto Total:'} data={invoice.total}/>
                        <DetailEntry name={'Porcentaje:'} data={invoice.detraction_percentage} unit={'%'}/>
                        <DetailEntry name={''} data={''}/>
                    </div>

                    <div className={'d-flex flex-column'}>
                        <DetailEntry name={'Razón Social Emisor:'} data={invoice.purchase_order.provider.name}/>
                        <DetailEntry name={'Razón Social Cliente:'} data={invoice.purchase_order.client.name}/>
                        <div style={{maxWidth: '300px'}} className={'mb-3'}>
                            <div className={`${styles.modalSmallSubtitle}`}>Tipo de Operación:</div>
                            <div className={'mt-1'}>{invoice?.operation_type.code + " - " + invoice?.operation_type.description}</div>
                        </div>
                        <div style={{maxWidth: '300px'}}>
                            <div  className={`${styles.modalSmallSubtitle}`}>Código BBSS:</div>
                            <div className={'mt-1'}>{(invoice?.bbss_code ? invoice?.bbss_code?.toString() : '') + " - " + (invoice?.bbs_object ? invoice?.bbs_object?.description : '')}</div>
                        </div>
                        <DetailEntry name={''} data={''}/>
                    </div>
                </div>

                <div>
                    <div className={`${styles.modalSubtitle} mt-4 mb-3`}>Estados</div>
                    <div className={'d-flex justify-content-between  ps-2'} style={{paddingRight: '70px'}}>
                        <DetailEntry name={'Estado OSE/SUNAT'} data={<div>{oseSunatToString(invoice?.sunat_status)}</div>}/>
                        <DetailEntry name={'Estado conformidad'} data={<div>{sunatToString(invoice?.conformity_status)}</div>}/>
                        <DetailEntry name={'Estado evaluación'} data={''} code={<div>{evalToString(invoice?.eval_status)}</div>}/>
                        <DetailEntry name={'Estado pago'} data={''} code={<div>{pagoToString(invoice?.payment_status)}</div>}/>
                    </div>
                </div>

                <div>
                    {invoice.observations?.length > 0 &&
                        <div>
                            <div className={`${styles.modalSubtitle}`}>Observaciones del Cliente</div>
                            <div className={`py-2`}>
                                <table style={{width: "100%", textAlign: 'center'}} className={'mt-1 mb-3'}>
                                <thead>
                                <tr style={{borderBottom: "#bbb solid 1px"}} className={styles.modalSmallSubtitle}>
                                    <th>Fecha de Subida</th>
                                    <th>Tipo de Documento</th>
                                    <th>Nombre de Archivo</th>
                                    <th>Ver</th>
                                    <th>Descargar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {invoice.observations.map((observation, index) => (
                                    <>
                                        {observation.files.length > 0 ? observation.files.map((file, fileIndex) => (
                                            <tr key={fileIndex} style={{borderBottom: "#bbb solid 1px"}}>
                                                <td>{formatTimestamp(observation.created_at)}</td>
                                                <td>{file.type}</td>
                                                <td>{file.name}</td>
                                                <td role={'button'} onClick={() => {
                                                    setShowObservationText(true);
                                                    setObservationText(observation.text);
                                                }}><FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'}/></td>
                                                <td role={'button'} onClick={() => downloadFile(file.id, file.name)}><FontAwesomeIcon icon={faDownload} size={'sm'}/></td>
                                            </tr>
                                        ))
                                            :
                                            <tr key={index} style={{borderBottom: "#bbb solid 1px"}}>
                                                <td>{formatTimestamp(observation.created_at)}</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td role={'button'} onClick={() => {
                                                    setShowObservationText(true);
                                                    setObservationText(observation.text);
                                                }}><FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'}/></td>
                                                <td role={'button'}></td>
                                            </tr>
                                        }
                                    </>

                                ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    {invoice.corrections?.length > 0 &&
                        <div>
                            <div className={`${styles.modalSubtitle}`}>Correciones del Proveedor</div>
                            <div className={`py-2`}>
                                <table style={{width: "100%", textAlign: 'center'}} className={'mt-1 mb-3'}>
                                    <thead>
                                    <tr style={{borderBottom: "#bbb solid 1px"}} className={styles.modalSmallSubtitle}>
                                        <th>Fecha de Subida</th>
                                        <th>Tipo de Documento</th>
                                        <th>Nombre de Archivo</th>
                                        <th>Ver</th>
                                        <th>Descargar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {invoice.corrections.map((correction, index) => (
                                        <>
                                            {correction.files.length > 0 ? correction.files.map((file, fileIndex) => (
                                                <tr key={fileIndex} style={{borderBottom: "#bbb solid 1px"}}>
                                                    <td>{formatTimestamp(correction.created_at)}</td>
                                                    <td>{file.type}</td>
                                                    <td>{file.name}</td>
                                                    <td role={'button'} onClick={() => {
                                                        setShowObservationText(true);
                                                        setObservationText(correction.text);
                                                    }}><FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'}/></td>
                                                    <td role={'button'} onClick={() => downloadFile(file.id, file.name)}><FontAwesomeIcon icon={faDownload} size={'sm'}/></td>
                                                </tr>
                                            ))
                                            :
                                                <tr key={index} style={{borderBottom: "#bbb solid 1px"}}>
                                                    <td>{formatTimestamp(correction.created_at)}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td role={'button'} onClick={() => {
                                                        setShowObservationText(true);
                                                        setObservationText(correction.text);
                                                    }}><FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'}/></td>
                                                    <td role={'button'}></td>
                                                </tr>
                                            }
                                        </>

                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>

                {isClient ?
                    (invoice.eval_status === 3 ?
                        <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                            <DangerButton onClick={() => setShowDetails(false)} name={'Cerrar'}/>
                        </div>
                        :
                        ((invoice.eval_status === 0 || invoice.eval_status === 1) ?
                            <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                                <div onClick={() => setShowModalAccept(true)} className={styles.modalButton} style={{backgroundColor: "#2F9636"}}><FontAwesomeIcon className={'pe-2'} icon={faCheck}/>Aceptar</div>
                                <div onClick={() => {
                                  setShowDetails(false);
                                  setShowObserve(true);
                                }} className={styles.modalButton} style={{backgroundColor: "#01C7E2"}}
                                ><FontAwesomeIcon className={'pe-2'} icon={faWarning}/>Observar</div>
                                <div onClick={() => setShowModalReject(true)} className={styles.modalButton} style={{backgroundColor: "#DC3545"}}><FontAwesomeIcon className={'pe-2'} icon={faX}/><div>Rechazar</div></div>
                                <DangerButton onClick={() => setShowDetails(false)} name={'Cerrar'}/>
                            </div>
                                :
                            ((invoice.eval_status === 2) &&
                                    <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                                        {invoice.payment_status === 0 && <div onClick={() => setShowModalPayment(true)} className={styles.modalButton} style={{backgroundColor: "#FFC107"}}><FontAwesomeIcon icon={faDollar} className={'pe-2'}/> Confirmar Pago</div>}
                                        <DangerButton onClick={() => setShowDetails(false)} name={'Cerrar'}/>
                                    </div>
                            )
                        ))
                    :
                    <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                        {invoice.eval_status === 1 &&
                            <div onClick={() => {
                                setShowDetails(false);
                                setShowRevisionModal(true)
                            }} className={`${styles.modalButton} d-flex align-items-center`} style={{backgroundColor: "#4A4DBB"}}>
                                <FontAwesomeIcon icon={faClipboard} className={'pe-2'}/>
                                Subsanar
                            </div>
                        }
                        <DangerButton onClick={() => setShowDetails(false)} name={'Cerrar'}/>
                    </div>
                }
            </Modal>

            {/* OBSERVE MODAL */}
            <Modal show={showObserve} setter={setShowObserve} name={'Observar'} hideClose={true}>
                <SelectInput greyBackground={true} form={observeForm} setForm={setObserveForm} name={'Tipo de Documento:'} internalName={'document_type'} options={[{id: 0, name: 'Guía de Remisión'}, {id: 1, name: "Acta de Conformidad"}, {id: 2, name: "Informe de servicio"}, {id: 3, name: "Nota de Credito"}]}/>
                <div className={'mb-2'}/>
                <BigTextInput form={observeForm} setForm={setObserveForm} name={'Ingresar Observaciones:'} internalName={'text'}/>

                <input type={'file'} accept={'.xml,.pdf'} multiple={true} onChange={(event) => {
                    const selectedFiles = Array.from(event.target.files);
                    setObserveForm({
                        ...observeForm,
                        "observation": selectedFiles
                    });
                }}/>

                <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                    <div onClick={() => observe()} className={styles.modalButton} style={{backgroundColor: "#4A4DBB"}}><FontAwesomeIcon icon={faSave} className={'pe-2'}/> Guardar</div>
                    <DangerButton onClick={() => setShowObserve(false)} name={'Cerrar'}/>
                </div>
            </Modal>

            {/*CORRECTION MODAL*/}
            <Modal show={showRevisionModal} hideClose={true} setter={setShowRevisionModal} name={'Subsanar'} size={'m'}>
                <div className={'d-flex justify-content-between align-items-center'}>
                    <div>Tipo Doc. a Reemplazar:</div>
                    <div style={{flexBasis: '50%'}}>
                        <SelectInput greyBackground={true} form={docForm} setForm={setDocForm} hideName={true} name={'Tipo Doc. a Reemplazar:'} internalName={'document_type'} options={[{id: 0, name: 'Guía de Remisión'}, {id: 1, name: "Acta de Conformidad"}, {id: 2, name: "Informe de servicio"}, {id: 3, name: "Nota de Credito"}]}/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mt-3 mb-4'}>
                    <div>Nro. Doc. Subsanado:</div>
                    <div style={{flexBasis: '50%'}}>
                        <div className={'px-2'} style={{
                            borderRadius: '6px',
                            backgroundColor: '#ddd'
                        }}>-</div>
                    </div>
                </div>

                <FileInput form={form} setForm={setForm} name={'Seleccionar archivo PDF'} accept={'.pdf'} onChange={(event) => {
                    setDocForm({
                        ...docForm,
                        "pdf": event.target.files[0]
                    });
                }}/>

                <FileInput form={form} setForm={setForm} name={'Seleccionar archivo XML'} accept={'.xml'} onChange={(event) => {
                    setDocForm({
                        ...docForm,
                        "xml": event.target.files[0]
                    });
                }}/>

                <FileInput form={form} setForm={setForm} name={'Otros Archivos (*.pdf, *.zip)'} accept={'.*'} onChange={(event) => {
                    const selectedFiles = Array.from(event.target.files);
                    setDocForm({
                        ...docForm,
                        "correction": selectedFiles
                    });
                }}/>

                <BigTextInput form={docForm} setForm={setDocForm} name={'Ingresar Observaciones:'} internalName={'text'}/>

                <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                    <div onClick={() => correct()} className={styles.modalButton} style={{backgroundColor: "#4A4DBB"}}><FontAwesomeIcon icon={faSave} className={'pe-2'}/> Guardar</div>
                    <DangerButton onClick={() => setShowRevisionModal(false)} name={'Cerrar'}/>
                </div>
            </Modal>

            <Modal show={showObservationText} setter={setShowObservationText} name={'Notas'}>
                <div className={'mb-3'}>{observationText}</div>
            </Modal>

            {/* MAIN CODE */}
            <div className={styles.container} style={!sidebarOpen ? {marginLeft: '80px'} : {marginLeft: '285px'}}>
                <Title name={'Facturas'}/>

                <div className={`${styles.filterContainer} mb-3`}>
                    <div className={'mb-2'}>Filtros</div>

                    <div className={'d-flex'}>
                        <div className={'d-flex flex-column'} style={{flexBasis: "19%",marginRight: "1%"}}>
                            <SelectInput greyBackground={true} name={'invoice_type'} placeholder={'Tipo Documento'} internalName={'invoice_type'} options={[{id: 0, name: 'Boleta'}, {id: 1, name: 'Factura'}]} form={form} setForm={setForm} hideName={true}/>
                            <div className={'mb-3'}/>
                            <DateInputAjonjolib placeholder={'Fecha de recepción'} onChange={(data) => {
                                setForm({
                                    ...form,
                                    'reception_date': data.startDate.toLocaleString('es')?.split(',')[0].replace(/\//g, '-'),
                                    'reception_date_end': data.endDate.toLocaleString('es')?.split(',')[0].replace(/\//g, '-')
                                })
                            }}/>
                        </div>

                        <div className={'d-flex flex-column'} style={{flexBasis: "19%",marginRight: "1%"}}>
                            <TextInput name={'Serie'} placeholder={'Serie'} internalName={'sn'} form={form} setForm={setForm} hideName={true}/>
                            <DateInputAjonjolib placeholder={'Fecha de emisión'} onChange={(data) => {
                                setForm({
                                    ...form,
                                    'issue_date': data.startDate.toLocaleString('es')?.split(',')[0].replace(/\//g, '-'),
                                    'issue_date_end': data.endDate.toLocaleString('es')?.split(',')[0].replace(/\//g, '-')
                                })
                            }}/>
                        </div>

                        <div className={'d-flex flex-column'} style={{flexBasis: "19%",marginRight: "1%"}}>
                            <TextInput name={'Número'} placeholder={'Número'} internalName={'sn_number'} form={form} setForm={setForm} hideName={true}/>
                            <SelectInput greyBackground={true} name={'eval_status'} placeholder={'Estado de evaluación'} internalName={'eval_status'} options={[{id: 0, name: 'Pendiente'}, {id: 1, name: 'Observado'}, {id: 2, name: 'Aceptado'}, {id: 3, name: 'Rechazado'}]} form={form} setForm={setForm} hideName={true}/>
                        </div>

                        <div className={'d-flex flex-column'} style={{flexBasis: "19%",marginRight: "1%"}}>
                            <TextInput name={'R.U.C. Cliente'} placeholder={'R.U.C. Cliente'} internalName={'ruc'} form={form} setForm={setForm} hideName={true}/>
                            <SelectInput greyBackground={true} name={'payment_status'} placeholder={'Estado de pago'} internalName={'payment_status'} options={[{id: 0, name: 'Pendiente'}, {id: 1, name: 'Pagado'}]} form={form} setForm={setForm} hideName={true}/>
                        </div>

                        <div className={'d-flex flex-column'} style={{flexBasis: "19%",marginRight: "1%"}}>
                            <TextInput name={'Nombre Cliente'} placeholder={'Nombre Cliente'} internalName={'client_name'} form={form} setForm={setForm} hideName={true}/>
                            <SelectInput greyBackground={true} name={'currency'} placeholder={'Moneda'} internalName={'currency'} options={[{id: 1, name: 'Soles'}, {id: 2, name: 'Dólares'}, {id: 3, name: "Euros"}]} form={form} setForm={setForm} hideName={true}/>
                        </div>
                    </div>

                    <div className={'d-flex justify-content-center mt-1'}>
                        <PrimaryButton name={'CONSULTAR'} onClick={query}/>
                    </div>
                </div>

                <div className={`${styles.secondaryContainer}`}>
                    <div className={'d-flex flex-row justify-content-between'}>
                        <div className={'d-flex flex-row'}>
                            <div>Opciones de listado:</div>
                            <div className={styles.downloadButton}>Excel</div>
                            {/*<div className={styles.downloadButton}>PDF</div>*/}
                        </div>
                        <div className={'d-flex align-items-center'}>
                            <div className={'pe-3'}>Buscar: </div>
                            <input className={styles.secondarySearch} value={secondaryQuery} onChange={(event) => {
                                setSecondaryQuery(event.target.value);
                            }}/>
                        </div>
                    </div>
                </div>

                <div className={'mt-3 pb-5'}>
                    {
                        //Pagination HERE
                        rows.length === 0 ? <Loading/> :
                            <TableComponent
                                withIndex={true}
                                pull={setRows}
                                cols={cols}
                                rows={filteredRows}
                                fetchUrl={'bill'}
                            />
                    }
                </div>
            </div>
        </div>
    );
}