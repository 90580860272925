import React, {useContext, useEffect, useState} from "react";
import {
    faBuilding,
    faCircleNotch,
    faCube,
    faFileInvoiceDollar, faFolder, faScrewdriverWrench,
    faUser,
    faUserGroup
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from './sidebar.module.css';
import {Link, useNavigate} from "react-router-dom";
import MyContext from "../../../MyContext";

export default function SidebarProvider({isOpen, index}) {
    const systemSettings = JSON.parse(localStorage.getItem('system_settings'));
    const [userType, setUserType] = useState(null);
    const navigate = useNavigate();
    const [items, setItems] = useState([
        {
            category: "Gestión de requerimientos",
            list: []
        },
        {
            category: "Configuración",
            list: []
        }
    ]);

    const {isClient, user} = useContext(MyContext);

    useEffect(() => {
        if(!user.role) return;
        console.log(user.role);
        let itemCopy = items;
        let toAddClient = [];
        let toAddProvider = [];

        if(user.role.buy_order) {
            toAddProvider.push({
                name: "Consulta OC",
                icon: faFolder,
                url: "/provider/buy_order",
                selected: index === 0
            });
        }

        if (user.role.invoice) {
            toAddProvider.push({
                name: "Consulta Facturas",
                icon: faFileInvoiceDollar,
                url: "/provider/invoice",
                selected: index === 1
            });
        }

        itemCopy[0].list = toAddProvider;

        if(isClient) {
            if(user.role.profile) {
                toAddClient.push({
                    name: "Mi Perfil",
                    icon: faUser,
                    url: "/provider/profile",
                    selected: index === 2
                });
            }

            if(user.role.company) {
                toAddClient.push({
                    name: "Empresas",
                    icon: faBuilding,
                    url: "/client/companies",
                    selected: index === 3
                });
            }
            if(user.role.user_settings) {
                toAddClient.push({
                    name: "Usuarios",
                    icon: faUserGroup,
                    url: "/client/users",
                    selected: index === 4
                });
            }
            if(user.role.user_profile) {
                toAddClient.push({
                    name: "Perfil de Usuario",
                    icon: faScrewdriverWrench,
                    url: "/client/user_profile",
                    selected: index === 5
                });
            }
        } else {
            if(user.role.profile) {
                toAddClient.push({
                    name: "Mi Perfil",
                    icon: faUser,
                    url: "/provider/profile",
                    selected: index === 2
                });
            }
        }
        itemCopy[1].list = toAddClient;
        setItems(itemCopy);
        console.log(itemCopy);
        setUserType(isClient);
    }, [user]);

    return (
        <div>
            {isOpen ?
                <div className={`pt-4 ${styles.mainContainer}`}>
                    {items?.map((item, index) => (
                        <div key={index}>
                            <div className={`${styles.line} mx-2 mb-3`}>
                                <div>{item.category}</div>
                            </div>
                            {index === 0 &&
                                <div className={`${styles.item} mb-2 ps-3`} style={{
                                    backgroundColor: `#e0e0e0`
                                }}>
                                    <FontAwesomeIcon className={'pe-2'} size={'xs'} icon={faCube}/>
                                    <div>{userType ? "Gestión de Cliente" : "Gestión de Proveedor"}</div>
                                </div>
                            }
                            {item?.list?.map((listItem, index) => (
                                <div onClick={() => {
                                    navigate(listItem.url);
                                }} role={'button'} className={`${styles.item} ${listItem.selected ? styles.selected : ""} mb-2 ps-3`}
                                      style={{background: `${listItem.selected ? `linear-gradient(to right, ${systemSettings.fade_start}, ${systemSettings.secondary_color})`: 'transparent'}`}}>
                                    <FontAwesomeIcon className={'pe-2'} size={'sm'} icon={listItem.icon}/>
                                    <div>{listItem.name}</div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                :
                <div className={`pt-4 ${styles.smallMainContainer}`}>
                    {items?.map((item, index) => (
                        <div key={index}>
                            {item?.list?.map((listItem, index) => (
                                <div onClick={() => {
                                    navigate(listItem.url);
                                }} role={'button'} className={`${styles.item} ${listItem.selected ? styles.selected : ""} mb-2 ps-3`}
                                      style={{background: `${listItem.selected ? `linear-gradient(to right, ${systemSettings.fade_start}, ${systemSettings.secondary_color})`: 'transparent'}`}}>
                                    <FontAwesomeIcon className={'pe-2'} size={'sm'} icon={listItem.icon}/>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}