import React, {useEffect, useState} from "react";
import axiosInstance from "../../../AxiosInstance";
import styles from "../../../styles/main.module.css";
import Header from "../../components/header/header";
import SidebarProvider from "../../components/sidebar/sidebar";
import Title from "../../../components/title/title";
import {PrimaryButton, RadioInput, SelectInput, TextInput} from "../../../components/input/inputs";
import Loading from "../../../components/loading/loading";
import TableComponent from "../../../components/table/table";
import {faAnglesUp, faClipboard, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../../components/delete_modal/delete_modal";
import Modal from "../../../components/modal/modal";
import {Select} from "../../../components/ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function MenuBox({name, url, active, onClick}) {
    return (
        <div className={'d-flex justify-content-between text-center mb-2'} style={{
            userSelect: 'none',
            color: '#4B5563',
        }}>
            <div style={{flexBasis: '33.33%', textAlign: 'left'}}>{name}</div>
            <div style={{flexBasis: '33.33%'}}>{url}</div>
            <div style={{flexBasis: '33.33%', cursor: 'pointer'}} onClick={() => onClick()}>
                {active ?
                    <div style={{borderRadius: '6px', color: '#FFF', backgroundColor: '#209118', fontWeight: 600}}>Activo</div>
                    :
                    <div style={{borderRadius: '6px', color: '#FFF', backgroundColor: '#f34933', fontWeight: 600}}>Inactivo</div>
                }
            </div>
        </div>
    );
}

export default function UserProfilesSettings() {
    const [showCreate, setShowCreate] = useState(false);
    const [showPermissions, setShowPermissions] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [editing, setEditing] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [users, setUsers] = useState([]);
    const [form, setForm] = useState({client_permission: 0, buy_order: false, invoice: false, profile: false, company: false, user_settings: false, user_profile: false});

    const preDelete = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const cols = [
        {title: "Nombre", internal: "name"},
        {title: "Tipo", internal: "client_permission", code: (row) => row.client_permission ? "Cliente" : "Proveedor"},
        {
            title: 'Acciones',
            buttons: [
                {
                    icon: faEdit,
                    color: "#44A04A",
                    onClick: (id) => {
                        const found = users.find((row) => row.id === id);
                        setShowCreate(true)
                        found.client_permission = found.client_permission ? 1 : 0;
                        setForm(found);
                        setEditing(true);
                    }
                },
                {
                    icon: faTrash,
                    color: "#DC3545",
                    onClick: preDelete
                }
            ]
        }
    ]

    useEffect(() => {
        axiosInstance.get('user_profile/').then((response) => {
            setUsers(response.data.results);
        });
    }, [])

    const deleteObj = () => {
        axiosInstance.delete('user_profile/' + deleteId + '/').then((response) => {
            window.location.reload();
        });
    }

    const create = () => {
        axiosInstance.post('user_profile/', form).then((response) => {
            window.location.reload();
        })
    }

    const [sidebarOpen, setSidebarOpen] = useState(true);

    useEffect(() => {
        if(form['client_permission'] === 0) {
            if(form['role'] !== 0) setForm({...form, role: 0});
        } else {
            if(form['role'] === 0) setForm({...form, role: 1});
        }
    }, [form['client_permission']]);

    return (
        <div className={styles.mainContainer}>
            <Header setSidebarOpen={setSidebarOpen}/>
            <SidebarProvider index={5} isOpen={sidebarOpen}/>

            <div className={styles.container} style={!sidebarOpen ? {marginLeft: '80px'} : {marginLeft: '285px'}}>
                <Title name={'Perfiles de usuario'}/>

                <Modal size={'m'} show={showCreate} setter={setShowCreate} name={`${editing ? "Editar" : "Agregar"} perfil`} buttonName={'Guardar'} onClick={() => {
                    if(editing) {
                          axiosInstance.put('user_profile/' + form.id + '/', form).then((response) => {
                            window.location.reload();
                        });
                    } else {
                        create();
                    }
                }}>
                    <div className={'d-flex justify-content-between'}>
                        <div style={{
                            flexBasis: '48%'
                        }}>
                            <TextInput grey={true} name={'Nombre de perfil'} internalName={'name'} form={form} setForm={setForm}/>
                        </div>
                        <div style={{
                            flexBasis: '48%'
                        }}>
                            <div style={{color: '#4B5563'}} className={'mb-1'}>Tipo</div>
                            <Select value={form['client_permission']} options={[{value: 0, name: "Proveedor"}, {value: 1, name: "Cliente"}]} onChange={(value) => {
                                setForm({...form, client_permission: value[0]})
                            }}/>
                        </div>
                    </div>
                    <div className={'d-flex justify-content-center'}>
                        <div onClick={() => {
                            setShowPermissions(true);
                        }} className={`${styles.modalButton} d-flex align-items-center`} style={{backgroundColor: "#4B5563"}}>
                            <FontAwesomeIcon icon={faAnglesUp} className={'pe-2'}/>
                            Asignar Permisos
                        </div>
                    </div>
                </Modal>

                <Modal size={'m'} show={showPermissions} setter={setShowPermissions} name={`Permisos`} buttonName={'Guardar'} hideClose={true} onClick={() => {
                    setShowPermissions(false);
                }}>
                    <div className={'d-flex justify-content-between text-center mb-2'} style={{
                        fontWeight: 600,
                        fontSize: '1.1rem',
                        color: '#4B5563'
                    }}>
                        <div style={{flexBasis: '33.33%'}}>Menú</div>
                        <div style={{flexBasis: '33.33%'}}>URL</div>
                        <div style={{flexBasis: '33.33%'}}>Estado</div>
                    </div>
                    <MenuBox name={'Consulta OC'} url={'/buy_order'} active={form['buy_order']} onClick={() => {
                        setForm({
                            ...form,
                            buy_order: !form.buy_order
                        })
                    }}/>
                    <MenuBox name={'Consulta Facturas'} url={'/invoice'} active={form['invoice']} onClick={() => {
                        setForm({
                            ...form,
                            invoice: !form.invoice
                        })
                    }}/>
                    <MenuBox name={'Mi Perfil'} url={'/profile'} active={form['profile']} onClick={() => {
                        setForm({
                            ...form,
                            profile: !form.profile
                        })

                    }}/>
                    <MenuBox name={'Empresas'} url={'/companies'} active={form['company']} onClick={() => {
                        setForm({
                            ...form,
                            company: !form.company
                        })
                    }}/>
                    <MenuBox name={'Usuarios'} url={'/users'} active={form['user_settings']} onClick={() => {
                        setForm({
                            ...form,
                            user_settings: !form.user_settings
                        })

                    }}/>
                    <MenuBox name={'Perfil de Usuario'} url={'/user_profile'} active={form['user_profile']} onClick={() => {
                        setForm({
                            ...form,
                            user_profile: !form.user_profile
                        })
                    }}/>
                    <div className={'mb-3'}></div>
                </Modal>

                <DeleteModal show={showDelete} setter={setShowDelete} name={'el perfil'} buttonName={'Guardar'} onClick={deleteObj}/>

                <div className={`${styles.secondaryContainer}`}>
                    <div className={'d-flex justify-content-end'}>
                        <PrimaryButton name={'Agregar perfil'} onClick={() => {
                            setForm({client_permission: 0, buy_order: false, invoice: false, profile: false, company: false, user_settings: false, user_profile: false});
                            setShowCreate(true)
                            setEditing(false);
                        }}/>
                    </div>

                    {
                        //Pagination HERE
                        users.length === 0 ? <Loading/> :
                            <TableComponent
                                fetchUrl={'user_profile'}
                                pull={setUsers}
                                cols={cols}
                                rows={users}
                            />
                    }
                </div>
            </div>
        </div>
    );
}