// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_titleContainer__YacBm {
    background-color: #FFFFFF;
    text-align: left;
    padding: 14px 24px;
    border-radius: 6px;
    font-size: large;
}`, "",{"version":3,"sources":["webpack://./src/components/title/title.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".titleContainer {\n    background-color: #FFFFFF;\n    text-align: left;\n    padding: 14px 24px;\n    border-radius: 6px;\n    font-size: large;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": `title_titleContainer__YacBm`
};
export default ___CSS_LOADER_EXPORT___;
