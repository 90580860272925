import React, {useEffect, useState} from "react";
import axiosInstance from "../../../AxiosInstance";
import styles from "../../../styles/main.module.css";
import Header from "../../components/header/header";
import SidebarProvider from "../../components/sidebar/sidebar";
import Title from "../../../components/title/title";
import {PrimaryButton, RadioInput, SelectInput, TextInput} from "../../../components/input/inputs";
import Loading from "../../../components/loading/loading";
import TableComponent from "../../../components/table/table";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../../components/delete_modal/delete_modal";
import Modal from "../../../components/modal/modal";
import {Select} from "../../../components/ajonjolib/inputs/ajonjolinput";

export default function UserSettings() {
    const [showCreate, setShowCreate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [editing, setEditing] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [form, setForm] = useState({});

    const preDelete = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const cols = [
        {title: "RUC", internal: "company.ruc"},
        {title: "Email", internal: "email"},
        {title: "Nombre", internal: "first_name"},
        {title: "Apellido", internal: "last_name"},
        {
            title: 'Acciones',
            buttons: [
                {
                    icon: faEdit,
                    color: "#44A04A",
                    onClick: (id) => {
                        const found = users.find((row) => row.id === id);
                        setShowCreate(true)
                        found.ruc = found.company.ruc;
                        found.is_staff = found.is_staff ? 1 : 0;
                        found.is_active = found.is_active ? 1: 0;
                        found.password = undefined;
                        setForm(found);
                        setEditing(true);
                    }
                },
                {
                    icon: faTrash,
                    color: "#DC3545",
                    onClick: preDelete
                }
            ]
        }
    ]

    useEffect(() => {
        axiosInstance.get('user/').then((response) => {
            setUsers(response.data.results);
        });

        axiosInstance.get('user_profile/').then((response) => {
            setRoles(response.data.results);
        });
    }, [])

    const deleteObj = () => {
        axiosInstance.delete('user/' + deleteId + '/').then((response) => {
            window.location.reload();
        });
    }

    const create = () => {
        if((form.password !== "" && form.password_2 !== "") || (form.password !== undefined && form.password_2 !== undefined)) {
            if (form.password !== form.password_2) {
                alert('Las contraseñas no coinciden');
                return;
            }
        }

        axiosInstance.post('register/', form).then((response) => {
            window.location.reload();
        })
    }

    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <div className={styles.mainContainer}>
            <Header setSidebarOpen={setSidebarOpen}/>
            <SidebarProvider index={4} isOpen={sidebarOpen}/>

            <div className={styles.container} style={!sidebarOpen ? {marginLeft: '80px'} : {marginLeft: '285px'}}>
                <Title name={'Usuarios'}/>

                <Modal size={'xl'} show={showCreate} setter={setShowCreate} name={`${editing ? "Editar" : "Agregar"} Usuario`} buttonName={'Guardar'} onClick={() => {
                    if(editing) {
                        if(form.password !== form.password_2) {
                            alert('Las contraseñas no coinciden');
                            return;
                        }

                        axiosInstance.put('user/' + form.id + '/', form).then((response) => {
                            window.location.reload();
                        });
                    } else {
                        create();
                    }
                }}>
                    <div className={'d-flex justify-content-between'}>
                        <div style={{
                            flexBasis: '35%'
                        }}>
                            <TextInput grey={true} name={'Nombres'} internalName={'first_name'} form={form} setForm={setForm}/>
                        </div>
                        <div style={{
                            flexBasis: '35%'
                        }}>
                            <TextInput grey={true} name={'Apellidos'} internalName={'last_name'} form={form} setForm={setForm}/>
                        </div>

                        <div style={{
                            flexBasis: '20%'
                        }}>
                            <TextInput grey={true} name={'DNI'} internalName={'dni'} form={form} setForm={setForm} type={'number'}/>
                        </div>
                    </div>

                    <div className={'d-flex justify-content-between'}>
                        <div style={{
                            flexBasis: '65%'
                        }}>
                            <TextInput grey={true} name={'Dirección'} internalName={'address'} form={form} setForm={setForm}/>
                        </div>

                        <div style={{
                            flexBasis: '30%'
                        }}>
                            <TextInput grey={true} name={'Teléfono'} internalName={'phone'} form={form} setForm={setForm} type={'number'}/>
                        </div>
                    </div>

                    <div className={'d-flex justify-content-between mb-3'}>
                        <div style={{
                            flexBasis: '31%'
                        }}>
                            <div>Tipo de Usuario</div>
                            <Select value={form['is_staff']} options={[{value: 0, name: "Proveedor"}, {value: 1, name: "Cliente"}]} onChange={(value) => {
                                setForm({...form, is_staff: value[0]})
                            }}/>
                        </div>

                        {form['is_staff'] ?
                            <div style={{
                                flexBasis: '31%'
                            }}>
                                <div>Perfil de Usuario</div>
                                <Select value={form['role']} options={roles?.map((x) => {return {name: x.name, value: x.id }})} onChange={(value) => {
                                    setForm({...form, role: value[0]})
                                }}/>
                            </div>
                            :
                            <div style={{
                                flexBasis: '31%'
                            }}>
                                <div>Perfil de Usuario</div>
                                <Select value={form['role']} options={roles?.map((x) => {return {name: x.name, value: x.id }})} onChange={(value) => {
                                    setForm({...form, role: value[0]})
                                }}/>
                            </div>
                        }

                        <div style={{
                            flexBasis: '31%'
                        }}>
                            <div>Estado</div>
                            <Select value={form['is_active']} options={[{value: 0, name: "Inactivo"}, {value: 1, name: "Activo"}]} onChange={(value) => {
                                setForm({...form, is_active: value[0]})
                            }}/>
                        </div>
                    </div>

                    <div className={'d-flex justify-content-between'}>
                        <div style={{
                            flexBasis: '48%'
                        }}>
                            <TextInput grey={true} name={'R.U.C. - Razón Social'} internalName={'ruc'} form={form} setForm={setForm}/>
                        </div>
                        <div style={{
                            flexBasis: '48%'
                        }}>
                            <TextInput grey={true} name={'Correo Electrónico'} internalName={'username'} form={form} setForm={setForm}/>
                        </div>
                    </div>

                    <div className={'d-flex justify-content-between'}>
                        <div style={{
                            flexBasis: '48%'
                        }}>
                            <TextInput grey={true} type={'password'} name={'Contraseña'} internalName={'password'} form={form} setForm={setForm}/>
                        </div>

                        <div style={{
                            flexBasis: '48%'
                        }}>
                            <TextInput grey={true} type={'password'} name={'Confirmar Contraseña'} internalName={'password_2'} form={form} setForm={setForm}/>
                        </div>
                    </div>
                </Modal>

                <DeleteModal show={showDelete} setter={setShowDelete} name={'el usuario'} buttonName={'Guardar'} onClick={deleteObj}/>

                <div className={`${styles.secondaryContainer}`}>
                    <div className={'d-flex justify-content-end'}>
                        <PrimaryButton name={'Agregar usuario'} onClick={() => {
                            setForm({is_active: 1, is_staff: 1, role: 1});
                            setShowCreate(true)
                            setEditing(false);
                        }}/>
                    </div>

                    {
                        //Pagination HERE
                        users.length === 0 ? <Loading/> :
                            <TableComponent
                                fetchUrl={'user'}
                                pull={setUsers}
                                cols={cols}
                                rows={users}
                            />
                    }
                </div>
            </div>
        </div>
    );
}