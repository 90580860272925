import React, {useEffect, useRef, useState} from "react";
import styles from './inputs.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faCloudArrowUp, faX} from "@fortawesome/free-solid-svg-icons";

function TextInput({name, internalName, placeholder, form, setForm, hideName, type, grey, floating}) {
    const [formName, setFormName] = useState(internalName);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const setField = (value) => {
        setForm({
            ...form,
            [formName]: value
        });
    }

    return (
        <div className="form-group mb-3" style={{position: 'relative'}}>
            { !hideName && <div className={`${styles.inputLabel} mb-1`}>{name}</div>}
            <input
                style={{
                    backgroundColor: grey ? '#D9D9D9' : '#fff'
                }}
                type={type}
                className={`${styles.inputBorderDefault} form-control rounded-2`}
                id={name}
                value={form[formName]}
                onChange={(event) => {
                    setField(event.target.value);
                }}
            />
            <div className={styles.floatingLabel}>
                {placeholder}
            </div>
        </div>
    )
}

function BigTextInput({name, internalName, placeholder, form, setForm}) {
    const [formName, setFormName] = useState(internalName);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const setField = (value) => {
        setForm({
            ...form,
            [formName]: value
        });
    }

    return (
        <div className="form-group mb-3">
            <div className={`${styles.inputLabel} mb-1`}>{name}</div>
            <textarea
                style={{
                    backgroundColor: '#D9D9D9'
                }}
                className={`${styles.inputBorderDefault} form-control rounded-2`}
                id={name}
                placeholder={placeholder}
                value={form[formName]}
                onChange={(event) => setField(event.target.value)}
            />
        </div>
    )
}

function SelectInput({ name, internalName, options, form, setForm, placeholder,  hideName, grey, greyBackground, floating }) {
    const [formName, setFormName] = useState(internalName);
    const [valueName, setValueName] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const setField = (value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [formName]: value,
        }));
        setIsOpen(false);
    };

    const setValue = (name, value) => {
        setValueName(name);
        setField(value)
    }

    return (
        <div className={`${styles.customSelect}`} ref={dropdownRef} style={{userSelect: 'none', position: 'relative'}}>
            {!hideName && <div className={`${styles.inputLabel}`}>{name}</div>}
            <div
                className={styles.dropdownButton}
                onClick={toggleDropdown}
                style={{
                    backgroundColor: grey ? '#D9D9D9' : '#fff'
                }}
            >
                <div>{valueName || <div></div>}</div>
                {isOpen ? <FontAwesomeIcon icon={faChevronUp}/> : <FontAwesomeIcon icon={faChevronDown}/>}
            </div>
            <div className={styles.floatingLabel}>
                {placeholder}
            </div>
            <div className={`${styles.dropdownOptions} ${isOpen ? styles.show : ''}`}>
                {options && options.map((option, index) => (
                    <div
                        style={{
                            fontSize: '14px',
                            textAlign: 'center',
                            borderBottom: '1px solid #9b9b9b',
                            fontWeight: 600,
                            backgroundColor: (grey || greyBackground) ? '#D9D9D9' : '#fff'
                        }}
                        key={index}
                        className={`${
                            form[formName] === option.id ? styles.selected : ''
                        }`}
                        onClick={() => setValue(option.name, option.id)}>
                        {option.name}
                    </div>
                ))}
            </div>
        </div>
    );
}

function RadioInput({name, internalName, options, form, setForm}) {
    const [formName, setFormName] = useState(internalName);
    const [checked, setChecked] = useState(form[formName]);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const setField = (value) => {
        setForm({
            ...form,
            [formName]: value
        });
    }

    const handleRadioChange = (index) => {
        setChecked(index);
        setField(index);
    }

    return (
        <div className="form-group mb-3">
            <div className={`${styles.inputLabel} mb-1`}>{name}</div>
            <div className={styles.customRadio}>
                {options.map((option, index) => (
                    <div key={index} className={styles.radioOption}>
                        <input
                            type="radio"
                            id={`${name}-${index}`}
                            name={name}
                            checked={checked === index}
                            onChange={() => handleRadioChange(index)}
                        />
                        <label htmlFor={`${name}-${index}`}>{option}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

function FileInput({name, internalName, form, setForm, onChange, accept}) {
    const [formName, setFormName] = useState(internalName);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const handleFileClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        setForm({
            ...form,
            [formName]: event.target.files[0]
        });
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        if(onChange) onChange(event);
    };

    return (
        <div className={styles.fileInputOutsideContainer}>
            <div
                className={`${styles.fileInputContainer} py-1`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div
                    className={styles.fileInput}
                    onClick={handleFileClick}
                >
                    <div className={styles.uploadText}>
                        <div className={'d-flex mx-2 my-1 px-2 py-1 align-items-center'} style={{
                            borderRadius: '6px',
                            backgroundColor: '#ddd'
                        }}>
                            {selectedFiles.length > 0 ? (
                                <div>
                                    {selectedFiles.map((file, index) => (
                                        <div key={index}>{file.name}</div>
                                    ))}
                                </div>
                            )
                                :
                                <div>{name}</div>
                            }
                        </div>
                    </div>
                </div>
                <input
                    type="file"
                    accept={accept}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </div>
        </div>
    )
}

function DateInput({name, internalName, form, setForm, hideName, placeholder, floating}) {
    const [formName, setFormName] = useState(internalName);

    useEffect(() => {
        if (!formName) {
            setFormName(name);
        }
    }, []);

    const setField = (value) => {
        setForm({
            ...form,
            [formName]: value
        });
    }

    return (
        <div className="form-group mb-3" style={{position: "relative"}}>
            <input
                type={'text'}
                className={`${styles.inputBorderDefault} form-control rounded-2`}
                id={name}
                value={form[formName]}
                onFocus={(event) => {
                    event.target.type = 'date';
                }}
                onChange={(event) => setField(event.target.value)}
            />
            <div className={styles.floatingLabel}>
                {placeholder}
            </div>
            {!hideName && <div className={`${styles.inputLabel} mb-1`} style={{
                fontSize: '12px',
                color: '#9b9b9b',
                textAlign: 'center'
            }}>{name}</div>}
        </div>
    )
}

function PrimaryButton({name, onClick, style}) {
    return (
        <div className={styles.primaryButton} style={style} onClick={onClick}>
            <div>{name}</div>
        </div>
    );
}

function SecondaryButton({name, onClick}) {
    return (
        <div className={styles.secondaryButton} onClick={onClick}>
            <div>{name}</div>
        </div>
    );
}

function DangerButton({name, onClick}) {
    return (
        <div className={styles.dangerButton} onClick={onClick}>
            <FontAwesomeIcon icon={faX} className={'pe-2'}/>
            <div>{name}</div>
        </div>
    );
}

export {
    TextInput,
    BigTextInput,
    SelectInput,
    RadioInput,
    FileInput,
    DateInput,

    PrimaryButton,
    SecondaryButton,
    DangerButton
};
