import React, {useContext, useState} from "react";
import styles from './header.module.css';
import {Link} from "react-router-dom";
import axiosInstance from "../../../AxiosInstance";
import MyContext from "../../../MyContext";

export default function Header({setSidebarOpen}) {
    const [menuOpen, setMenuOpen] = useState(false);
    const systemSettings = JSON.parse(localStorage.getItem('system_settings'));
    const {user} = useContext(MyContext);
    console.log(user);

    const logout = () => {
        localStorage.clear();
        axiosInstance.defaults.headers.common['Authorization'] = null;
        window.location.href = '/login';
    }

    return (
        <div className={`${styles.headerContainer} py-2 px-5`} style={{backgroundColor: systemSettings.main_color}}>
            <div>
                <div className={'d-flex align-items-center'}>
                    <img src={systemSettings.image}/>
                    <div className={`${styles.sidebarShow}`} onClick={() => {
                        if(setSidebarOpen) setSidebarOpen((prev) => !prev);
                    }}>
                        <div style={{
                            backgroundColor: '#FFF',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%'
                        }}/>
                    </div>
                </div>
            </div>
            <div className={'d-flex align-items-center'}>
                <div style={{
                    color: '#FFF',
                }}>
                    <div>{user?.first_name} {user?.last_name}</div>
                    <div>{user?.email}</div>
                </div>
                <div style={{
                    width: '50px',
                    height: '50px',
                }} role={'button'} onClick={() => setMenuOpen((prev) => !prev)}>
                    <img src={'/images/user_img.png'} height={'50px'} width={'50px'}/>
                </div>
            </div>

            <div className={`${styles.dropdown} ${!menuOpen && styles.hide}`}>
                <Link to={'/provider/profile'} className={`${styles.dropdownItem}`}>User</Link>
                <div onClick={() => logout()} className={`${styles.dropdownItem}`}>Log Out</div>
            </div>
        </div>
    );
}