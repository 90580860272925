// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  background-color: #F4F5FA;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
  background-color: #F4F5FA;
}

#root {
  zoom: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;AACd;;AAEA;;;EAGE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,SAAS;AACX","sourcesContent":[".App {\n  text-align: center;\n  background-color: #F4F5FA;\n  height: 100%;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  background-color: #F4F5FA;\n}\n\n#root {\n  zoom: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
