import React, {useEffect} from "react";
import { Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from "./providers/login/login";
import Register from "./providers/register/register";
import BuyOrdersProviders from "./providers/buy_orders/buy_orders";
import InvoicesProvider from "./providers/invoices/invoices";
import CompanySettings from "./providers/configuration/companies/companies";
import ProviderProfile from "./providers/configuration/profile/profile";
import UserSettings from "./providers/configuration/users/users";
import LoginProvider from "./providers/login_provider/login";
import UserProfilesSettings from "./providers/configuration/user_profile/user_profile";

function LoginRedirect() {
    useEffect(() => {
        window.location.replace('/login');
    }, []);
    return (<div></div>);
}

export default function RouteContainer() {
    let authenticated = localStorage.getItem('access_token') !== null;

    return (
        <Routes>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/login_provider'} element={<LoginProvider/>}/>
            <Route path={'/register'} element={<Register/>}/>

            {authenticated && <>
                <Route path={'/provider/buy_order'} element={<BuyOrdersProviders/>}/>
                <Route path={'/provider/invoice'} element={<InvoicesProvider/>}/>
                <Route path={'/provider/profile'} element={<ProviderProfile/>}/>
                <Route path={'/client/companies'} element={<CompanySettings/>}/>
                <Route path={'/client/users'} element={<UserSettings/>}/>
                <Route path={'/client/user_profile'} element={<UserProfilesSettings/>}/>
            </>}

            <Route path="*" element={<LoginRedirect/>}/>
        </Routes>
    );
}