import React, {useState} from "react";
import styles from './register.module.css';
import {PrimaryButton, TextInput} from "../../components/input/inputs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import axiosInstance from "../../AxiosInstance";

export default function Register() {
    const [form, setForm] = useState({});
    const systemSettings = JSON.parse(localStorage.getItem('system_settings'));

    const register = () => {
        axiosInstance.post('register/', form).then((response) => {
            window.location.replace('/login');
        }).catch((err) => {

        });
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.provider} style={{backgroundImage: "url('images/login_background.png')"}}>
                <img src={systemSettings.image}/>
            </div>
            <div className={styles.login}>
                <Link to={'/login'} className={'d-flex flex-row ms-5 mb-2'}>
                    <FontAwesomeIcon role={'button'} icon={faArrowLeft} className={'pt-1 pe-2'} color={'#4A4DB9'}/>
                    <div role={'button'} style={{color: "#4A4DB9"}}>Regresar al inicio</div>
                </Link>
                <div className={`${styles.title} mb-4`}>Portal de Proveedores</div>

                <div className={styles.loginBox}>
                    <div className={styles.loginTitle}>Regístrate</div>
                    <div className={`mt-1 mb-3`}>Completa los campos para crear una cuenta</div>

                    <div>
                        <TextInput name={'RUC *'} internalName={'ruc'} form={form} setForm={setForm} placeholder={'Ingresa el número de RUC (RUC 10 o 20)'} hideName={true}/>
                        <TextInput name={'NOMBRE *'} internalName={'first_name'} form={form} setForm={setForm} placeholder={'Ingresa tu nombre'} hideName={true}/>
                        <TextInput name={'APELLIDO *'} internalName={'last_name'} form={form} setForm={setForm} placeholder={'Ingresa tu apellido'} hideName={true}/>
                        <TextInput name={'CELULAR *'} internalName={'phone'} form={form} setForm={setForm} placeholder={'Ingresa tu número de celular'} hideName={true}/>
                        <TextInput name={'CORREO *'} internalName={'username'} form={form} setForm={setForm} placeholder={'Ingresa tu correo electrónico'} hideName={true}/>
                        <TextInput name={'CONTRASEÑA *'} internalName={'password'} form={form} setForm={setForm} placeholder={'Ingresa tu contraseña'} hideName={true}/>
                        <PrimaryButton name={'Continuar'} onClick={register}/>
                        <div style={{fontSize: "0.6rem", textAlign: "center"}}>Al darle click a “Continuar” aceptas que datos personales se utilizarán para mejorar tu experiencia en esta plataforma, gestionar accesos a tu cuenta y otros propósitos descritos en nuestras Políticas de Privacidad.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}