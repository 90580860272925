// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date_dateContainer__yUD4G {
    position: absolute;
    z-index: 998;
    padding-top: 0.2rem;
}

.date_container__gMaQy {
    position: relative;
}

.date_floatingLabel__7UtDU {
    position: absolute;
    top: -10px;
    pointer-events: none;
    left: 12px;
    display: block;
    background-color: #FFFFFF;
    font-size: 0.8rem;
    padding-left: 3px;
    padding-right: 3px;
}

.date_defaultBorder__8qBmB {
    white-space: nowrap;
    height: 43px;
    padding: 10px !important;
    border: 1px solid #CCCCCC !important;
    font-size: 14px !important;
    color: rgba(51, 51, 51, 80%) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/inputs/date/date.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,oBAAoB;IACpB,UAAU;IACV,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,wBAAwB;IACxB,oCAAoC;IACpC,0BAA0B;IAC1B,uCAAuC;AAC3C","sourcesContent":[".dateContainer {\n    position: absolute;\n    z-index: 998;\n    padding-top: 0.2rem;\n}\n\n.container {\n    position: relative;\n}\n\n.floatingLabel {\n    position: absolute;\n    top: -10px;\n    pointer-events: none;\n    left: 12px;\n    display: block;\n    background-color: #FFFFFF;\n    font-size: 0.8rem;\n    padding-left: 3px;\n    padding-right: 3px;\n}\n\n.defaultBorder {\n    white-space: nowrap;\n    height: 43px;\n    padding: 10px !important;\n    border: 1px solid #CCCCCC !important;\n    font-size: 14px !important;\n    color: rgba(51, 51, 51, 80%) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateContainer": `date_dateContainer__yUD4G`,
	"container": `date_container__gMaQy`,
	"floatingLabel": `date_floatingLabel__7UtDU`,
	"defaultBorder": `date_defaultBorder__8qBmB`
};
export default ___CSS_LOADER_EXPORT___;
