// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_container__mx-db {
    font-family: "Century Gothic";
}

.modal_header__ZrtET {
    padding: 16px;
    border-bottom: rgba(51, 51, 51, 0.4) solid 1px;
    margin-bottom: 1rem;
}

.modal_noBorder__CzJt0 {
    border-bottom: none;
    margin-bottom: 0 !important;
}

.modal_header__ZrtET div:first-child {
    color: rgba(51, 51, 51, 80%);
    font-size: 20px;
    font-weight: 400;
}

.modal_header__ZrtET div:last-child {
    font-weight: 400;
}

.modal_body__MX-ja {
    font-weight: 400;
    padding-right: 16px;
    padding-left: 16px;
}

.modal_footer__O9LYa {
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/modal.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,8CAA8C;IAC9C,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".container {\n    font-family: \"Century Gothic\";\n}\n\n.header {\n    padding: 16px;\n    border-bottom: rgba(51, 51, 51, 0.4) solid 1px;\n    margin-bottom: 1rem;\n}\n\n.noBorder {\n    border-bottom: none;\n    margin-bottom: 0 !important;\n}\n\n.header div:first-child {\n    color: rgba(51, 51, 51, 80%);\n    font-size: 20px;\n    font-weight: 400;\n}\n\n.header div:last-child {\n    font-weight: 400;\n}\n\n.body {\n    font-weight: 400;\n    padding-right: 16px;\n    padding-left: 16px;\n}\n\n.footer {\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `modal_container__mx-db`,
	"header": `modal_header__ZrtET`,
	"noBorder": `modal_noBorder__CzJt0`,
	"body": `modal_body__MX-ja`,
	"footer": `modal_footer__O9LYa`
};
export default ___CSS_LOADER_EXPORT___;
