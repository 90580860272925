import React, {useContext, useEffect, useState} from "react";
import Header from "../components/header/header";
import SidebarProvider from "../components/sidebar/sidebar";
import styles from '../../styles/main.module.css';
import {
    BigTextInput,
    DangerButton,
    FileInput,
    PrimaryButton,
    SelectInput,
    TextInput
} from "../../components/input/inputs";
import TableComponent from "../../components/table/table";
import Loading from "../../components/loading/loading";
import axiosInstance from "../../AxiosInstance";
import Title from "../../components/title/title";
import Modal from "../../components/modal/modal";
import MyContext from "../../MyContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCheck,
    faDollar,
    faDownload,
    faMagnifyingGlass, faSave, faUpload,
    faWarning,
    faX
} from "@fortawesome/free-solid-svg-icons";
import {toast, ToastTypes} from "../../components/toasts/toast/toast";
import {DateInput as DateInputAjonjolib, Select} from "../../components/ajonjolib/inputs/ajonjolinput";
import {oseSunatToString, sunatToString} from "../../utils";

function DetailEntry({name, data, code, unit}) {
    return (
        <div className={'mb-3'}>
            <div className={`${styles.modalSmallSubtitle}`}>{name}</div>
            <div className={'mt-1'}>{data ? data : (code ? '' : (name ? '-' : ''))} {unit ? unit : ''}</div>
            {code && code}
        </div>
    );
}

export default function BuyOrdersProviders() {
    const [oc, setOC] = useState({client: {}, currency: {}, order_details: []});
    const [ocTotalLeft, setOCTotalLeft] = useState(0);
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [form, setForm] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [showNEC, setShowNEC] = useState(false);
    const [currentNEC, setCurrentNEC] = useState({currency: {}, nec_details: [], files: []});
    const [showUpload, setShowUpload] = useState(false);
    const [documentForm, setDocumentForm] = useState({});
    const {isClient} = useContext(MyContext);
    const systemSettings = JSON.parse(localStorage.getItem('system_settings'));
    const [userType, setUserType] = useState("");
    const [currentInvoice, setCurrentInvoice] = useState({client: {}, currency: {}, purchase_order: {provider: {}, client: {}}, operation_type: {},detraction_currency: {}, order_detail: {}, observations: []});
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [currentInvoiceName, setCurrentInvoiceName] = useState('')

    const [observeForm, setObserveForm] = useState({});
    const [showObserve, setShowObserve] = useState(false);
    const [showModalAccept, setShowModalAccept] = useState(false);
    const [showModalReject, setShowModalReject] = useState(false);
    const [showModalPayment, setShowModalPayment] = useState(false);
    const [showSuccessUpload, setShowSuccessUpload] = useState(false);
    const [showObservationText, setShowObservationText] = useState(false);
    const [observationText, setObservationText] = useState("");

    const [secondaryQuery, setSecondaryQuery] = useState("");

    useEffect(() => {
        if(isClient) {
            setUserType("proveedor");
        } else {
            setUserType("cliente");
        }
    }, [isClient]);

    useEffect(() => {
        axiosInstance.get('purchase_order/').then((response) => {
            setRows(response.data.results)
            setFilteredRows(response.data.results);
        });
    }, []);

    const formatTimestamp = (timestamp) => {
        if(timestamp == null) return "-";
        const date = new Date(timestamp);
        return date.toLocaleString();
    }

    const query = () => {
        const queryParams = Object.entries(form)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");
        const url = `purchase_order/?${queryParams}`;
        axiosInstance.get(url).then((response) => {
            setRows(response.data.results);
            setFilteredRows(response.data.results);
        })
    }

    const statusToString = (row, data) => {
        switch (data) {
            case -1:
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>CARGANDO...</div>;
            case 0:
                return <div className={styles.pill} style={{backgroundColor: "#333333"}}>PENDIENTE</div>;
            case 1:
                return <div className={styles.pill} style={{backgroundColor: "#FFC107"}}>OBSERVADO</div>;
            case 2:
                return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>COMPLETADO</div>;
            case 3:
                return <div className={styles.pill} style={{backgroundColor: "#EA8823"}}>EN REVISIÓN</div>;
            case 4:
                return <div className={styles.pill} style={{backgroundColor: "#4A4DBB"}}>PARCIAL</div>;
            default:
                return "DESCONOCIDO";
        }
    }

    const cols = [
        {title: "Fecha de emisión", internal: "date"},
        {title: "Tipo OC", internal: "type", code: (row, data) => {return <div>{data === 0 ? 'Suministro' : 'Servicio'}</div>}},
        {title: "N° de Orden de Compra", internal: "number"},
        {title: userType, internal: "client.name"},
        {title: "RUC", internal: "client.ruc"},
        {title: "Moneda", internal: "currency.name"},
        {title: "Monto Total", internal: "total", code: (row, data) => {return parseFloat(data).toFixed(2)}},
        {title: "Estado OC", internal: "status", code: (row, data) => statusToString(row, data) },
        {title: "Acciones", internal: 'type', code: (row, data) => {
                return (
                    <div
                        onClick={() => {
                            console.log(row);
                            let billed_amount = 0;
                            row.order_details.forEach((detail) => {
                                const bills = row?.bills?.filter((x) => x.order_detail?.id === detail.id);
                                if(bills.length > 0) {
                                    billed_amount += detail.total;
                                }
                            })
                            setOC({
                                ...row,
                                amount_left: (row.total - billed_amount).toFixed(2)
                            })
                            setShowDetails(true);
                        }}
                        role={'button'} className={styles.pill} style={{backgroundColor: "#FFF", border: "#333333 solid 1px", color: "#333333"}}>Detalles</div>
                );
            }}
    ]

    const downloadOC = () => {
        axiosInstance.get('purchase_order/5/download-pdf/', {
            responseType: 'blob'
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${oc.number}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    const downloadExcel = () => {
        axiosInstance.get('purchase_order/excel/', {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'your_model_data.xlsx');
            document.body.appendChild(link);

            // Simulate a click event to trigger the download
            link.click();

            // Clean up the temporary link and URL object
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    const uploadFiles = () => {
        let formData = new FormData();
        for(const key in documentForm) {
            console.log(documentForm[key]);
            formData.append(key, documentForm[key]);
        }
        formData.append("order_detail_id", currentNEC?.id);

        axiosInstance.post('bill/', formData).then((response) => {
            setShowUpload(false);
            setShowNEC(true);
            setShowSuccessUpload(true);
            const queryParams = Object.entries(form)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");
            const url = `purchase_order/?${queryParams}`;
            axiosInstance.get(url).then((response) => {
                setRows(response.data.results);
                const responseData = response.data.results;
                setFilteredRows(responseData);

                //find current oc in responseData and set it as current oc
                const foundOC = responseData.filter((x) => x?.id === oc?.id);
                if(foundOC.length > 0) setOC({...foundOC[0],
                    amount_left: (foundOC[0].total - foundOC[0].bills.reduce((acc, x) => acc + x.total, 0)).toFixed(2)}
                );

                //find currentNec in founcOC and set it as currentNEC
                const foundNEC = foundOC[0].order_details.filter((x) => x?.id === currentNEC?.id);
                if(foundNEC.length > 0) setCurrentNEC(foundNEC[0]);
                setDocumentForm({});
            })
        });
    }

    const renderDocuments = (currentOC) => {
        const bills = currentOC.bills.filter((x) => x.order_detail?.id === currentNEC?.id);
        let found_bill = null;
        if(bills.length > 0) found_bill = bills[0];

        return (
            <div className={'d-flex justify-content-between'}>
                <div style={{flexBasis: '48%', marginRight: '2%'}}>
                    <div className={'mb-2'}><b className={`${styles.modalSmallSubtitle} pe-2`}>Tipo de documento asociado:</b>Factura</div>
                    <div><b className={`${styles.modalSmallSubtitle} pe-2`}>N. Documento Asociado:</b> {found_bill?.sn}</div>
                </div>
                <div  className={`${styles.modalSmallSubtitle} d-flex flex-column`} style={{flexBasis: '48%', marginRight: '2%'}}>
                    <div className={'d-flex align-items-center mb-2'}><b className={`${styles.modalSmallSubtitle} pe-2`}>Estado OSE/SUNAT:</b>{oseSunatToString(currentInvoice?.sunat_status)}</div>
                    <div className={'d-flex align-items-center'}><b className={`${styles.modalSmallSubtitle} pe-2`}>Estado de conformidad:</b> {sunatToString(currentInvoice?.conformity_status)}</div>
                </div>
            </div>
        )
    }

    const evalToString = (str) => {
        switch (str) {
            case -1:
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>CARGANDO...</div>;
            case 0:
                return <div className={styles.pill} style={{backgroundColor: "#333"}}>PENDIENTE</div>;
            case 1:
                return <div className={styles.pill} style={{backgroundColor: "#FFC107"}}>OBSERVADO</div>;
            case 2:
                return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>ACEPTADO</div>;
            case 3:
                return <div className={styles.pill} style={{backgroundColor: "#FF1235"}}>RECHAZADO</div>;
            default:
                return "DESCONOCIDO";
        }
    }

    const pagoToString = (str) => {
        if(str === 0) return <div className={styles.pill} style={{backgroundColor: "#333"}}>PENDIENTE</div>;
        return <div className={styles.pill} style={{backgroundColor: "#2F9636"}}>RECIBIDO</div>;
    }

    const rechazar = () => {
        const reason = form['rejection_reason'][0];
        const comment = form['rejection_comment'];

        toast('Factura Rechazada', ToastTypes.ERROR);
        axiosInstance.post('sunat/', {
            ruc_client: currentInvoice.purchase_order.client.ruc,
            ruc_provider: currentInvoice.purchase_order.provider.ruc,
            bill_id: currentInvoice.id,
            conformity: 2,
            motives: reason,
            comment: comment
        }).then((response) => {
            if(response.status === 200) {
                axiosInstance.put('bill_status/' + currentInvoice.id + '/', {
                    status: 3
                }).then((response) => {
                    window.location.reload();
                })
            }
        });
    }

    const downloadFile = (id, name) => {
        axiosInstance.get(`file/${id}/`, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}`);
            document.body.appendChild(link);

            // Simulate a click event to trigger the download
            link.click();

            // Clean up the temporary link and URL object
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    const observe = () => {
        let formData = new FormData();
        for (const key in observeForm) {
            if (key === 'observation') {
                // If the key is 'observation', it's an array of files
                const files = observeForm[key];
                for (let i = 0; i < files.length; i++) {
                    formData.append(key, files[i]);
                }
            } else {
                formData.append(key, observeForm[key]);
            }
        }
        formData.append("bill_id", currentInvoice?.id);

        axiosInstance.post('observation/', formData).then((response) => {
            window.location.reload()
        });
    }

    const setStatus = (status, conformity) => {
        //console.log(currentInvoice);
        //return;
        axiosInstance.post('sunat/', {
            ruc_provider: currentInvoice.purchase_order.provider.ruc,
            ruc_client: currentInvoice.purchase_order.client.ruc,
            bill_id: currentInvoice.id,
            conformity: conformity
        }).then((response) => {
            if(response.status === 200) {
                axiosInstance.put('bill_status/' + currentInvoice.id + '/', {
                    status: status
                }).then((response) => {
                    window.location.reload();
                });
            }
        })
    }

    const setPayment = () => {
        axiosInstance.post('bill_status/' + currentInvoice?.id + '/').then((response) => {
            window.location.reload();
        })
    }

    useEffect(() => {
        if(secondaryQuery === undefined || secondaryQuery === "") setFilteredRows(rows);
        const lowercaseQuery = secondaryQuery.toLowerCase();
        const filtered = rows.filter((row) => {
            return (row?.client?.name.includes(lowercaseQuery)
                || row?.client?.ruc?.toString().includes(lowercaseQuery)
                || row?.number?.toString().includes(lowercaseQuery)
                || row?.total?.toString().includes(lowercaseQuery)
                || (row?.type === 0 ? "suministro".includes(lowercaseQuery) : "servicio".includes(lowercaseQuery))
            );
        })
        setFilteredRows(filtered);
    }, [secondaryQuery]);

    const [sidebarOpen, setSidebarOpen] = useState(true);

    const evaluar_button = () => {
        const bills = oc?.bills?.filter((x) => x.order_detail?.id === currentNEC?.id);
        let found_bill = null;
        if(bills?.length > 0) found_bill = bills[0];
        if(!found_bill) return "";
        return (
            <div onClick={() => {
                const bills = oc.bills.filter((x) => x.order_detail?.id === currentNEC?.id);
                let found_bill = null;
                if(bills.length > 0) found_bill = bills[0];
                setCurrentInvoice(found_bill);
                setShowInvoiceModal(true);
                setShowNEC(false);
            }} className={styles.modalButton} style={{backgroundColor: "#40a297"}}>
                <FontAwesomeIcon icon={faArrowRight} className={'pe-2'}/>
                Evaluar
            </div>
        )
    }

    return (
        <div className={styles.mainContainer}>

            <Header setSidebarOpen={setSidebarOpen}/>
            <SidebarProvider isOpen={sidebarOpen} index={0}/>

            <Modal show={showModalAccept} setter={setShowModalAccept} name={'Aceptar'} onClick={() => setStatus(2, 1)} buttonName={'Aceptar'}>
                <div className={'mb-3'}>¿Seguro que deseas aceptar la factura {currentInvoice?.sn}?</div>
            </Modal>

            <Modal show={showModalReject} setter={setShowModalReject} name={'Rechazar'} onClick={() => rechazar()} buttonName={'Rechazar'}>
                <div className={'mb-3'}>¿Seguro que deseas rechazar la factura {currentInvoice?.sn}?</div>
                <div>Motivo de rechazo: </div>
                <Select options={[{value: 1, name: 'Plazo de pago acordado'}, {value: 2, name: 'Monto neto pendiente de pago'}, {value: 3, name: 'Reclamo respecto de bienes adquiridos o servicios prestados'}]} value={form['rejection_reason']} onChange={(value) => setForm({...form, rejection_reason: value})}/>
                <div className={'mb-2'}/>
                <div>Comentario: </div>
                <TextInput value={form['rejection_comment']} internalName={'rejection_comment'} form={form} setForm={setForm}/>
                <div className={'mb-3'}/>
            </Modal>

            <Modal show={showModalPayment} setter={setShowModalPayment} name={'Confirmar Pago'} onClick={() => setPayment()} buttonName={'Confirmar'}>
                <div className={'mb-3'}>¿Seguro que deseas confirmar el pago de la factura {currentInvoice?.sn}?</div>
            </Modal>

            {/* OBSERVE MODAL */}
            <Modal show={showObserve} setter={setShowObserve} name={'Observar'} hideClose={true}>
                <SelectInput greyBackground={true} form={observeForm} setForm={setObserveForm} name={'Tipo de Documento:'} internalName={'document_type'} options={[{id: 0, name: 'Guía de Remisión'}, {id: 1, name: "Acta de Conformidad"}, {id: 2, name: "Informe de servicio"}, {id: 3, name: "Nota de Credito"}]}/>
                <div className={'mb-2'}/>
                <BigTextInput form={observeForm} setForm={setObserveForm} name={'Ingresar Observaciones:'} internalName={'text'}/>

                <input type={'file'} accept={'.xml,.pdf'} multiple={true} onChange={(event) => {
                    const selectedFiles = Array.from(event.target.files);
                    setObserveForm({
                        ...observeForm,
                        "observation": selectedFiles
                    });
                }}/>

                <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                    <div onClick={() => observe()} className={styles.modalButton} style={{backgroundColor: "#4A4DBB"}}><FontAwesomeIcon icon={faSave} className={'pe-2'}/> Guardar</div>
                </div>
            </Modal>

            {/* DETAILS MODAL */}
            <Modal size={'xl'} show={showDetails} setter={setShowDetails} name={'Detalles de la Orden de Compra'}>
                <div className={`${styles.modalSubtitle} mb-2`}>Datos: </div>
                <div className={'d-flex justify-content-between'}>
                    <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1.25%"}}>
                        <DetailEntry name={'Orden de Compra'} data={oc.number}/>
                        <DetailEntry name={'Monto'} data={parseFloat(oc.total).toFixed(2)}/>
                        <DetailEntry name={'RUC del cliente'} data={oc.client.ruc}/>
                    </div>

                    <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1.25%"}}>
                        <DetailEntry name={'Fecha de Emisión'} data={oc.date}/>
                        <DetailEntry name={'Moneda'} data={oc.currency.name}/>
                        <DetailEntry name={'Razón Social del cliente'} data={oc.client.name}/>
                    </div>

                    <div className={'d-flex flex-column'} style={{flexBasis: "50%"}}>
                        <DetailEntry name={'Observaciones'} code={
                            <div className={'px-2 py-2'} style={{
                                borderRadius: "5px",
                                minHeight: '100px',
                                color: '#666',
                                backgroundColor: "#d5d5d5",
                                userSelect: 'none'
                            }}>{oc.observations}</div>
                        }/>
                        <DetailEntry name={''} code={
                            <div onClick={() => downloadOC()} role={'button'} className={'d-flex justify-content-center fw-bold align-items-center'} style={{

                                color: '#4A4DB9',
                                userSelect: 'none'
                            }}>
                                <FontAwesomeIcon icon={faDownload} color={'#4A4DB9'} className={'pe-2'}/>
                                <div style={{fontWeight: '600'}}>Descargar OC</div>
                            </div>
                        }/>
                    </div>
                </div>

                <div className={`${styles.modalSubtitle} mt-4`}>Detalle NEC:</div>
                <table style={{width: "100%", textAlign: 'center'}} className={'mt-1 mb-3'}>
                    <thead>
                    <tr style={{borderBottom: "#bbb solid 1px"}} className={styles.modalSmallSubtitle}>
                        <th>N°</th>
                        <th>Identificador</th>
                        <th>Tipo</th>
                        <th>Factura Asociada</th>
                        <th style={{maxWidth: '70px', wordWrap: "break-word"}}>Estado</th>
                        <th>Monto</th>
                        <th>Detalle</th>
                    </tr>
                    </thead>
                    <tbody>
                    {oc.order_details?.map((detail, index) => {
                        const bills = oc.bills.filter((x) => x.order_detail?.id === detail?.id);
                        let found_bill = null;
                        if(bills.length > 0) found_bill = bills[0];

                        return (<tr key={index} style={{borderBottom: "#bbb solid 1px"}}>
                            <td>{index + 1}</td>
                            <td>{detail.nec_number}</td>
                            <td>NEC</td>
                            <td>{found_bill?.sn}</td>
                            <td style={{maxWidth: '70px', wordWrap: "break-word"}}>{found_bill ? 'Asociado' : 'Sin asociar'}</td>
                            <td>{detail.currency.symbol} {parseFloat(detail.total).toFixed(2)}</td>
                            <td style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }} role={'button'} onClick={() => {
                                const bills = oc.bills.filter((x) => x.order_detail?.id === detail?.id);
                                let found_bill = null;
                                if(bills.length > 0) found_bill = bills[0];
                                
                                if(found_bill) {
                                    found_bill.sunat_status = -1;
                                    found_bill.conformity_status = -1;
                                }

                                setCurrentInvoice(found_bill);
                                setShowDetails(false);
                                setShowNEC(true);
                                setCurrentNEC(detail);
                                setCurrentInvoiceName(found_bill?.sn);

                                axiosInstance.get(`sunat/${found_bill?.id}/`).then((response) => {
                                    if(response) {
                                        console.log(response.data);
                                        if (response?.status === 200 && response?.data) {
                                            const response_data = response.data;
                                            setCurrentInvoice({
                                                ...found_bill,
                                                sunat_status: response_data.ose_sunat_status,
                                                conformity_status: response_data.conformity_status
                                            });
                                        }
                                    } else {
                                        setCurrentInvoice({
                                            ...found_bill,
                                            sunat_status: -2,
                                            conformity_status: -2
                                        });
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                    setCurrentInvoice({
                                        ...found_bill,
                                        sunat_status: -2,
                                        conformity_status: -2
                                    });
                                });
                            }}>
                                <div style={{
                                    width: "25px",
                                    height: "auto",
                                    borderRadius: '6px',
                                    backgroundColor: systemSettings.main_color,
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} color={'#FFF'}/>
                                </div>
                            </td>
                        </tr>)
                    })}
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{maxWidth: '70px', wordWrap: "break-word"}}><b>Monto por facturar</b></td>
                        <td>{oc.currency.symbol} {parseFloat(oc.amount_left).toFixed(2)}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </Modal>

            {/* NEC MODAL */}
            <Modal show={showNEC} setter={setShowNEC} name={`Detalles de la NEC (${oc?.number})`} size={'xl'} hideClose={true}>
                <div className={`${styles.modalSubtitle} mb-2`}>Datos: </div>
                <div className={'d-flex justify-content-between'}>
                    <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1.25%"}}>
                        <DetailEntry name={'N° NEC'} data={currentNEC.nec_number}/>
                    </div>
                    <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1.25%"}}>
                        <DetailEntry name={'RUC del cliente'} data={oc.client.ruc}/>
                    </div>
                    <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1.25%"}}>
                        <DetailEntry name={'Razón Social del cliente'} data={oc.client.name}/>
                    </div>
                    <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1.25%"}}>
                        <DetailEntry name={'Monto'} data={parseFloat(currentNEC.total).toFixed(2)}/>
                    </div>
                    <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1.25%"}}>
                        <DetailEntry name={'Moneda'} data={currentNEC.currency.name}/>
                    </div>
                </div>

                <div className={`${styles.modalSubtitle} mt-4 mb-3`}>Detalle NEC:</div>
                <table style={{width: "100%", textAlign: 'center'}} className={'mt-1 mb-3'}>
                    <thead>
                    <tr style={{borderBottom: "#bbb solid 1px"}} className={styles.modalSmallSubtitle}>
                        <th>N°</th>
                        <th>Cantidad</th>
                        <th>Descripción</th>
                        <th>Precio Unitario</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentNEC.nec_details.map((nec, index) => (
                        <tr key={index} style={{borderBottom: "#bbb solid 1px", fontWeight: 400}} className={styles.modalSmallSubtitle}>
                            <td>{index + 1}</td>
                            <td>{nec.quantity}</td>
                            <td>{nec.description}</td>
                            <td>{nec.currency?.symbol} {parseFloat(nec.unit_price).toFixed(2)}</td>
                            <td>{nec.currency?.symbol} {parseFloat(nec.total).toFixed(2)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                {oc.type === 0 &&
                    <div className={'my-5'}>
                        <div className={`${styles.modalSubtitle} mt-4 mb-3`}>Documentos Adjuntos:</div>
                        <div>{renderDocuments(oc)}</div>
                    </div>
                }
                <table style={{width: "100%", textAlign: 'center'}} className={'mt-1 mb-3'}>
                    <thead>
                    <tr style={{borderBottom: "#bbb solid 1px"}}  className={styles.modalSmallSubtitle}>
                        <th>Fecha de Subida</th>
                        <th>Tipo de Documento</th>
                        <th>Nombre del Archivo</th>
                        <th>Descargar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentNEC.files.map((file, index) => {
                        const bills = oc.bills.filter((x) => x.order_detail?.id === currentNEC.id);
                        let found_bill = null;
                        if(bills.length > 0) found_bill = bills[0];
                        if(!found_bill) return "";
                        return (
                        <tr key={index} style={{borderBottom: "#bbb solid 1px"}}>
                            <td>{formatTimestamp(file.upload_date)}</td>
                            <td>{file.type}</td>
                            <td>{file.name}</td>
                            <td style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }} role={'button'} onClick={() => {
                                downloadFile(file.id, file.name);
                            }}>
                                <div style={{
                                    width: "25px",
                                    height: "auto",
                                    borderRadius: '6px',
                                    backgroundColor: systemSettings.main_color,
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}>
                                    <FontAwesomeIcon icon={faDownload} color={'#FFF'}/>
                                </div>
                            </td>
                        </tr>
                    )})}
                    </tbody>
                </table>

                <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>

                {!isClient &&
                    <div onClick={() => {
                        setShowNEC(false);
                        setShowUpload(true);
                    }} className={styles.modalButton} style={{backgroundColor: "#01C7E2"}}>
                        <FontAwesomeIcon icon={faUpload} className={'pe-2'}/>
                        Subir Archivo
                    </div>
                }



                {isClient &&
                    evaluar_button()
                }
                    <DangerButton onClick={() => {
                        setShowDetails(true);
                        setShowNEC(false)
                    }} name={'Cerrar'}/>
                </div>
            </Modal>

            {/*EVALUAR*/}
            <Modal size={'xl'} hideClose={true} show={showInvoiceModal} otherSetter={() => {setShowInvoiceModal(false); setShowNEC(true);}} setter={setShowInvoiceModal} name={'Detalle del Comprobante'}>
                <div className={`${styles.modalSubtitle} mb-2`}>Datos del comprobante</div>
                <div className={'d-flex justify-content-between px-2'}>
                    <div className={'d-flex flex-column'}>
                        <DetailEntry name={'Tipo Comprobante:'} data={currentInvoice?.type === 0 ? 'Boleta' : 'Factura'}/>
                        <DetailEntry name={'Fecha de Emisión:'} data={currentInvoice?.issue_date}/>
                        <DetailEntry name={'Forma de Pago:'} data={currentInvoice?.payment_terms ? currentInvoice.payment_terms : '-'}/>
                        <DetailEntry name={'Monto Detracción:'} code={
                            <div>{currentInvoice?.detraction_amount ? parseFloat(currentInvoice?.detraction_amount).toFixed(2) : '-'}</div>
                        }/>
                        <DetailEntry name={'Orden de Compra:'} data={currentInvoice?.purchase_order.number}/>
                    </div>

                    <div className={'d-flex flex-column'}>
                        <DetailEntry name={'Serie y Número:'} data={currentInvoice?.sn}/>
                        <DetailEntry name={'Fecha de Vencimiento:'} data={currentInvoice?.due_date}/>
                        <DetailEntry name={'Moneda:'} data={currentInvoice?.currency?.name}/>
                        <DetailEntry name={'Moneda Detracción:'} data={currentInvoice?.detraction_currency?.name}/>
                        <DetailEntry name={'N° NEC:'} data={currentInvoice?.order_detail.nec_number}/>
                    </div>

                    <div className={'d-flex flex-column'}>
                        <DetailEntry name={'RUC del Emisor:'} data={currentInvoice?.purchase_order.provider.ruc}/>
                        <DetailEntry name={'RUC del Cliente:'} data={currentInvoice?.purchase_order.client.ruc}/>
                        <DetailEntry name={'Monto Total:'} data={currentInvoice?.total}/>
                        <DetailEntry name={'Porcentaje:'} data={currentInvoice?.detraction_percentage} unit={'%'}/>
                        <DetailEntry name={''} data={''}/>
                    </div>

                    <div className={'d-flex flex-column'}>
                        <DetailEntry name={'Razón Social Emisor:'} data={currentInvoice?.purchase_order.provider.name}/>
                        <DetailEntry name={'Razón Social Cliente:'} data={currentInvoice?.purchase_order.client.name}/>
                        <div style={{maxWidth: '300px'}} className={'mb-3'}>
                            <div className={`${styles.modalSmallSubtitle}`}>Tipo de Operación:</div>
                            <div className={'mt-1'}>{currentInvoice?.operation_type.code + " - " + currentInvoice?.operation_type.description}</div>
                        </div>
                        <div style={{maxWidth: '300px'}}>
                            <div className={`${styles.modalSmallSubtitle}`}>Código BBSS:</div>
                            <div className={'mt-1'}>{(currentInvoice?.bbss_code ? currentInvoice?.bbss_code.toString() : '') + " - " + (currentInvoice?.bbs_object ? currentInvoice?.bbs_object?.description : "")}</div>
                        </div>
                        <DetailEntry name={''} data={''}/>
                    </div>
                </div>



                <div>
                    <div className={`${styles.modalSubtitle} mt-4 mb-3`}>Estados</div>
                    <div className={'d-flex justify-content-between ps-2'} style={{paddingRight: '70px'}}>
                        <DetailEntry name={'Estado OSE/SUNAT'} code={<div>{oseSunatToString(currentInvoice?.sunat_status)}</div>}/>
                        <DetailEntry name={'Estado conformidad'} code={<div>{sunatToString(currentInvoice?.conformity_status)}</div>}/>
                        <DetailEntry name={'Estado evaluación'} data={''} code={<div>{evalToString(currentInvoice?.eval_status)}</div>}/>
                        <DetailEntry name={'Estado pago'} data={''} code={<div>{pagoToString(currentInvoice?.payment_status)}</div>}/>
                    </div>
                </div>

                <div>
                    {currentInvoice?.observations?.length > 0 &&
                        <div>
                            <div className={`${styles.modalSubtitle}`}>Observaciones del Cliente</div>
                            <div className={`py-2`}>
                                <table style={{width: "100%", textAlign: 'center'}} className={'mt-1 mb-3'}>
                                    <thead>
                                    <tr style={{borderBottom: "#bbb solid 1px"}}  className={styles.modalSmallSubtitle}>
                                        <th>Fecha de Subida</th>
                                        <th>Tipo de Documento</th>
                                        <th>Nombre de Archivo</th>
                                        <th>Ver</th>
                                        <th>Descargar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentInvoice?.observations.map((observation, index) => (
                                        <>
                                            {observation.files.length > 0 ? observation.files.map((file, fileIndex) => (
                                                <tr key={fileIndex} style={{borderBottom: "#bbb solid 1px"}}>
                                                    <td>{formatTimestamp(observation.created_at)}</td>
                                                    <td>{file.type}</td>
                                                    <td>{file.name}</td>
                                                    <td role={'button'} onClick={() => {
                                                        setShowObservationText(true);
                                                        setObservationText(observation.text);
                                                    }}><FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'}/></td>
                                                    <td role={'button'} onClick={() => downloadFile(file.id, file.name)}><FontAwesomeIcon icon={faDownload} size={'sm'}/></td>
                                                </tr>
                                            ))
                                                :
                                                <tr key={index} style={{borderBottom: "#bbb solid 1px"}}>
                                                    <td>{formatTimestamp(observation.created_at)}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td role={'button'} onClick={() => {
                                                        setShowObservationText(true);
                                                        setObservationText(observation.text);
                                                    }}><FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'}/></td>
                                                    <td role={'button'}></td>
                                                </tr>
                                            }
                                        </>

                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    {currentInvoice?.corrections?.length > 0 &&
                        <div>
                            <div className={`${styles.modalSubtitle}`}>Correciones del Proveedor</div>
                            <div className={`py-2`}>
                                <table style={{width: "100%", textAlign: 'center'}} className={'mt-1 mb-3'}>
                                    <thead>
                                    <tr style={{borderBottom: "#bbb solid 1px"}} className={styles.modalSmallSubtitle}>
                                        <th>Fecha de Subida</th>
                                        <th>Tipo de Documento</th>
                                        <th>Nombre de Archivo</th>
                                        <th>Ver</th>
                                        <th>Descargar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentInvoice.corrections.map((correction, index) => (
                                        <>
                                            {correction.files.length > 0 ? correction.files.map((file, fileIndex) => (
                                                <tr key={fileIndex} style={{borderBottom: "#bbb solid 1px"}}>
                                                    <td>{formatTimestamp(correction.created_at)}</td>
                                                    <td>{file.type}</td>
                                                    <td>{file.name}</td>
                                                    <td role={'button'} onClick={() => {
                                                        setShowObservationText(true);
                                                        setObservationText(correction.text);
                                                    }}><FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'}/></td>
                                                    <td role={'button'} onClick={() => downloadFile(file.id, file.name)}><FontAwesomeIcon icon={faDownload} size={'sm'}/></td>
                                                </tr>
                                            ))
                                            :
                                                <tr key={index} style={{borderBottom: "#bbb solid 1px"}}>
                                                    <td>{formatTimestamp(correction.created_at)}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td role={'button'} onClick={() => {
                                                        setShowObservationText(true);
                                                        setObservationText(correction.text);
                                                    }}><FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'}/></td>
                                                    <td role={'button'}></td>
                                                </tr>
                                            }
                                        </>

                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>

                {isClient ?
                    (currentInvoice?.eval_status === 3 ?
                        <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                            <DangerButton onClick={() => {setShowInvoiceModal(false); setShowNEC(true);}} name={'Cerrar'}/>
                        </div>
                        :
                        ((currentInvoice?.eval_status === 0 || currentInvoice?.eval_status === 1) ?
                                <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                                    <div onClick={() => setShowModalAccept(true)} className={styles.modalButton} style={{backgroundColor: "#2F9636"}}><FontAwesomeIcon className={'pe-2'} icon={faCheck}/>Aceptar</div>
                                    <div onClick={() => {
                                        setShowDetails(false);
                                        setShowObserve(true);
                                    }} className={styles.modalButton} style={{backgroundColor: "#01C7E2"}}
                                    ><FontAwesomeIcon className={'pe-2'} icon={faWarning}/>Observar</div>
                                    <div onClick={() => setShowModalReject(true)} className={styles.modalButton} style={{backgroundColor: "#DC3545"}}><FontAwesomeIcon className={'pe-2'} icon={faX}/><div>Rechazar</div></div>
                                    <DangerButton onClick={() => {setShowInvoiceModal(false); setShowNEC(true);}} name={'Cerrar'}/>
                                </div>
                                :
                                ((currentInvoice?.eval_status === 2) &&
                                    <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                                        {currentInvoice?.payment_status === 0 && <div onClick={() => setShowModalPayment(true)} className={styles.modalButton} style={{backgroundColor: "#FFC107"}}><FontAwesomeIcon icon={faDollar} className={'pe-2'}/> Confirmar Pago</div>}
                                        <DangerButton onClick={() => {setShowInvoiceModal(false); setShowNEC(true);}} name={'Cerrar'}/>
                                    </div>
                                )
                        ))
                    :
                    <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                        {currentInvoice?.eval_status === 1 &&
                            <div onClick={() => {
                                //setShowDetails(false);
                                //setShowRevisionModal(true)
                                //setShowRevisionModal(true)
                            }} className={styles.modalButton} style={{backgroundColor: "#4A4DBB"}}>Subsanar</div>
                        }
                        <DangerButton onClick={() => {setShowInvoiceModal(false); setShowNEC(true);}} name={'Cerrar'}/>
                    </div>
                }
            </Modal>

            {/* UPLOAD MODAL */}
            <Modal show={showUpload} setter={setShowUpload} name={'Subir Documento'} size={'m'} hideClose={true}>
                <div className={'d-flex'}>
                    <div className={'me-1'}><b>Tip. Doc. Relacionado: </b></div>
                    <div className={'px-2'} style={{
                        borderRadius: '6px',
                        backgroundColor: '#ddd'
                    }}>Factura</div>
                </div>

                <div className={'d-flex my-2'}>
                    <div className={'me-1'}><b>Nro. Doc. Relacionado: </b></div>
                    <div className={'px-2'} style={{
                        borderRadius: '6px',
                        backgroundColor: '#ddd'
                    }}>{currentInvoiceName}</div>
                </div>

                {oc.type === 1 &&
                    <div>
                        <FileInput form={form} setForm={setForm} name={'Seleccionar factura PDF'} accept={'.pdf'} onChange={(event) => {
                            setDocumentForm({
                                ...documentForm,
                                "pdf": event.target.files[0]
                            });
                        }}/>

                        <FileInput form={form} setForm={setForm} name={'Seleccionar factura XML'} accept={'.xml'} onChange={(event) => {
                            setDocumentForm({
                                ...documentForm,
                                "xml": event.target.files[0]
                            });
                        }}/>

                        <FileInput form={form} setForm={setForm} name={'Seleccionar acta de conformidad PDF'} accept={'.pdf'} onChange={(event) => {
                            setDocumentForm({
                                ...documentForm,
                                "pdf_guide": event.target.files[0]
                            });
                        }}/>

                        <FileInput form={form} setForm={setForm} name={'Seleccionar informe de servicio PDF'} accept={'.pdf'}  onChange={(event) => {
                            setDocumentForm({
                                ...documentForm,
                                "other_pdf": event.target.files[0]
                            });
                        }}/>

                        <FileInput form={form} setForm={setForm} name={'Otro Archivo'} accept={'.*'} onChange={(event) => {
                            setDocumentForm({
                                ...documentForm,
                                "other_guide_2": event.target.files[0]
                            });
                        }}/>
                    </div>
                }

                {oc.type === 0 &&
                    <div>
                        <FileInput form={form} setForm={setForm} name={'Seleccionar archivo XML'} accept={'.xml'} onChange={(event) => {
                            setDocumentForm({
                                ...documentForm,
                                "xml": event.target.files[0]
                            });
                        }}/>

                        <FileInput form={form} setForm={setForm} name={'Seleccionar archivo PDF'} accept={'.pdf'} onChange={(event) => {
                            setDocumentForm({
                                ...documentForm,
                                "pdf": event.target.files[0]
                            });
                        }}/>

                        <FileInput form={form} setForm={setForm} name={'Otro archivo PDF'} accept={'.pdf'}  onChange={(event) => {
                            setDocumentForm({
                                ...documentForm,
                                "other_pdf": event.target.files[0]
                            });
                        }}/>
                    </div>
                }

                {oc.type === 0 &&
                <div className={'mt-4'}>
                    <div className={'d-flex'}>
                        <div className={'me-1'}><b>Tip. Doc. Relacionado: </b></div>
                        <div className={'px-2'} style={{
                            borderRadius: '6px',
                            backgroundColor: '#ddd'
                        }}>Guía de Remisión</div>
                    </div>

                    <div className={'d-flex my-2'}>
                        <div className={'me-1'}><b>Nro. Doc. Relacionado: </b></div>
                        <div className={'px-2'} style={{
                            borderRadius: '6px',
                            backgroundColor: '#ddd'
                        }}>{currentInvoiceName}</div>
                    </div>

                    <FileInput form={form} setForm={setForm} name={'Seleccionar archivo XML'} accept={'.xml'}  onChange={(event) => {
                        setDocumentForm({
                            ...documentForm,
                            "xml_guide": event.target.files[0]
                        });
                    }}/>

                    <FileInput form={form} setForm={setForm} name={'Seleccionar archivo PDF'} accept={'.pdf'} onChange={(event) => {
                        setDocumentForm({
                            ...documentForm,
                            "pdf_guide": event.target.files[0]
                        });
                    }}/>

                    <FileInput form={form} setForm={setForm} name={'Otro Archivo'} accept={'.*'} onChange={(event) => {
                        setDocumentForm({
                            ...documentForm,
                            "other_guide_1": event.target.files[0]
                        });
                    }}/>

                    <FileInput form={form} setForm={setForm} name={'Otro Archivo'} accept={'.*'} onChange={(event) => {
                        setDocumentForm({
                            ...documentForm,
                            "other_guide_2": event.target.files[0]
                        });
                    }}/>
                </div>
                }

                <div className={'d-flex justify-content-center align-items-center align-content-center mt-3'}>
                    <div onClick={() => uploadFiles()} className={styles.modalButton} style={{backgroundColor: "#4A4DBB"}}>Guardar</div>
                    <DangerButton onClick={() => setShowUpload(false)} name={'Cerrar'}/>
                </div>
            </Modal>

            <Modal show={showObservationText} setter={setShowObservationText} name={'Notas'}>
                <div className={'mb-3'}>{observationText}</div>
            </Modal>

            <Modal show={showSuccessUpload} setter={setShowSuccessUpload} name={'Subir Documentos'}>
                <div className={'mb-3'}>Se cargaron correctamente los archivos</div>
            </Modal>


            {/* INVOICE CODE */}
            <div className={styles.container} style={!sidebarOpen ? {marginLeft: '80px'} : {marginLeft: '285px'}}>
                <Title name={'Orden de Compra'}/>

                <div className={`${styles.filterContainer} mb-3`} style={{width: "100%"}}>
                    <div className={'mb-3'}>Filtros</div>

                    {userType !== 'cliente' ?
                        <div className={'d-flex'} style={{width: "100%"}}>
                            <div className={'d-flex flex-column'} style={{flexBasis: "20%", marginRight: "1%"}}>
                                <SelectInput greyBackground={true} name={'Tipo OC'} placeholder={'Tipo OC'} hideName={true} form={form} setForm={setForm} internalName={'oc_type'} options={[{id: 0, name: 'Suministro'}, {id: 1, name: 'Servicio'}]}/>
                                <div className={'mt-3'}/>
                                <DateInputAjonjolib placeholder={'Fecha de emisión'} onChange={(data) => {
                                    setForm({
                                        ...form,
                                        'issue_date': data.startDate.toLocaleString('es')?.split(',')[0].replace(/\//g, '-'),
                                        'issue_date_end': data.endDate.toLocaleString('es')?.split(',')[0].replace(/\//g, '-')
                                    })
                                }}/>
                            </div>

                            <div className={'d-flex flex-column'} style={{flexBasis: "20%", marginRight: "1%"}}>
                                <TextInput name={'NOC'} placeholder={'N° Orden de Compra'} internalName={'number'} form={form} setForm={setForm} hideName={true}/>
                                <SelectInput greyBackground={true} name={'sunat'} placeholder={'Estado OSE/SUNAT'} hideName={true} form={form} setForm={setForm} internalName={'sunat'} options={[{id: 0, name: 'Aceptado'}, {id: 1, name: 'Rechazado'}, {id: 2, name: 'Baja'}, {id: 3, name: 'Pendiente'}]}/>
                            </div>

                            <div className={'d-flex flex-column'} style={{flexBasis: "20%", marginRight: '1%'}}>
                                <TextInput name={'RUC'} placeholder={'R.U.C. del Proveedor'} internalName={'ruc'} form={form} setForm={setForm} hideName={true}/>
                                <SelectInput greyBackground={true} name={'Estado OC'} placeholder={'Estado OC'} hideName={true} form={form} setForm={setForm} internalName={'status'} options={[{id: 0, name: 'Pendiente'}, {id: 1, name: 'Observado'}, {id: 2, name: 'Completado'}, {id: 3, name: 'En revisión'}, {id: 4, name: 'Parcial'}]}/>
                            </div>

                            <div className={'d-flex flex-column'} style={{flexBasis: "40%"}}>
                                <TextInput name={'NAME'} placeholder={'Nombre del Proveedor'} internalName={'name'} form={form} setForm={setForm} hideName={true}/>
                                <div className={'d-flex'}>
                                    <div style={{flexBasis: '48%', marginRight: '4%'}}>
                                        <SelectInput greyBackground={true} name={'currency'} placeholder={'Moneda'} internalName={'currency'} options={[{id: 1, name: 'Soles'}, {id: 2, name: 'Dólares'}, {id: 3, name: "Euros"}]} form={form} setForm={setForm} hideName={true}/>
                                    </div>
                                    <div style={{flexBasis: '48%'}}>
                                        <TextInput name={'Monto Total'} placeholder={'Monto Total'} internalName={'total'} form={form} setForm={setForm} hideName={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={'d-flex'} style={{width: "100%"}}>
                            <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1%"}}>
                                <SelectInput greyBackground={true} name={'Tipo OC'} placeholder={'Tipo OC'} hideName={true} form={form} setForm={setForm} internalName={'oc_type'} options={[{id: 0, name: 'Suministro'}, {id: 1, name: 'Servicio'}]}/>
                                <div className={'mt-3'}/>
                                <DateInputAjonjolib placeholder={'Fecha de emisión'} onChange={(data) => {
                                    setForm({
                                        ...form,
                                        'issue_date': data.startDate.toLocaleString('es')?.split(',')[0].replace(/\//g, '-'),
                                        'issue_date_end': data.endDate.toLocaleString('es')?.split(',')[0].replace(/\//g, '-')
                                    })
                                }}/>
                            </div>

                            <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: "1%"}}>
                                <TextInput name={'NOC'} placeholder={'N° Orden de Compra'} internalName={'number'} form={form} setForm={setForm} hideName={true}/>
                                <SelectInput greyBackground={true} name={'Estado OC'} placeholder={'Estado OC'} hideName={true} form={form} setForm={setForm} internalName={'status'} options={[{id: 0, name: 'Pendiente'}, {id: 1, name: 'Observado'}, {id: 2, name: 'Completado'}, {id: 3, name: 'En revisión'}, {id: 4, name: 'Parcial'}]}/>
                            </div>

                            <div className={'d-flex flex-column'} style={{flexBasis: "24%", marginRight: '1%'}}>
                                <TextInput name={'RUC'} placeholder={'R.U.C. del Cliente'} internalName={'ruc'} form={form} setForm={setForm} hideName={true}/>
                                <SelectInput greyBackground={true} name={'currency'} placeholder={'Moneda'} internalName={'currency'} options={[{id: 1, name: 'Soles'}, {id: 2, name: 'Dólares'}, {id: 3, name: "Euros"}]} form={form} setForm={setForm} hideName={true}/>
                            </div>

                            <div className={'d-flex flex-column'} style={{flexBasis: "24%"}}>
                                <TextInput name={'NAME'} placeholder={'Nombre del Cliente'} internalName={'name'} form={form} setForm={setForm} hideName={true}/>
                                <TextInput name={'Monto Total'} placeholder={'Monto Total'} internalName={'total'} form={form} setForm={setForm} hideName={true} />
                            </div>
                        </div>
                    }

                    <div className={'d-flex justify-content-center mt-1'}>
                        <PrimaryButton name={'CONSULTAR'} onClick={query}/>
                    </div>
                </div>

                <div className={`my-4 ${styles.secondaryContainer}`}>
                    <div className={'d-flex flex-row align-items-center justify-content-between'}>
                        <div className={'d-flex flex-row'}>
                            <div>Opciones de listado:</div>
                            <div className={styles.downloadButton} onClick={() => downloadExcel()}>Excel</div>
                            {/*<div className={styles.downloadButton}>PDF</div>*/}
                        </div>
                        <div className={'d-flex align-items-center'}>
                            <div className={'pe-3'}>Buscar: </div>
                            <input className={styles.secondarySearch} value={secondaryQuery} onChange={(event) => {
                                setSecondaryQuery(event.target.value);
                            }}/>
                        </div>
                    </div>
                </div>

                <div className={'pb-5'}>
                    {
                        //Pagination HERE
                        rows.length === 0 ? <Loading/> :
                            <TableComponent
                                withIndex={true}
                                cols={cols}
                                rows={filteredRows}
                                pull={setRows}
                                fetchUrl={'purchase_order'}
                            />
                    }
                </div>
            </div>
        </div>
    );
}